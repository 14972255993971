import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../components/Router";
import Icons from "../../../global/icons";

import Lang from "../../../global/Lang";
import { handleColorType } from "../../../utils/ButtonStyle";
import RuleModal from "./RuleModal";
import RewardModal from "./RewardModal";
import { useClaimQuestPointsMutation } from "../../../services/questApi";
import { useSelector } from "react-redux";
import { selectAccount } from "../../../slices/accountSlice";
import { getLanguage } from "../../../services/common";
import { LangString } from "../../../utils";

import { ErrorMySwal } from "../../../global/MySwal";

import { useGetAccountProfileQuery } from "../../../services/accountApi";
import { useDispatch } from "react-redux";
import { setAccountProfile } from "../../../slices/accountSlice";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
  color: #fcfcfc;
  padding: 20px 0;
  display: flex;
  flex: 1;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuestWrapper = styled.div``;

const QuestContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(40vw, auto)); */
  grid-gap: 40px;

  ${RWD_LG(css`
    grid-gap: 20px;
  `)};

  ${RWD_MD(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const QusetCard = styled.div`
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const QuestCardTitleWrapper = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const QuestCardTitle = styled.div`
  font-size: min(max(1.302vw, 20px), 25px);
  font-weight: bold;
`;

const QusetCardInfoIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }

  & path {
    stroke: #fcfcfc;
  }
`;

const QuesetCardContent = styled.div`
  color: #d3d3d3;
  padding-bottom: 20px;
  font-size: min(max(0.9375vw, 16px), 18px);
`;
const QuestCardReward = styled.div`
  display: flex;
  align-items: baseline;
`;

const ProgressRewardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const ProgressWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ProgressNum = styled.div`
  color: #54587b;
  padding-left: 10px;
`;

const Progress = styled.div`
  width: 100%;
  height: 5px;
  background: #0aecd1;

  background: ${(props) => (props.status ? "#0aecd1" : "#3a3d5a")};
  border-right: 5px solid #54587b;

  :last-child {
    border-right: 0px;
  }
`;

const QuestCardRewardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const QuestCardRewardPoint = styled.div`
  font-size: min(max(2.5vw, 40px), 48px);
  font-family: Montserrat;
  font-weight: bold;
  color: #0aecd1;
  padding-right: 10px;
`;

const QuestButton = styled.button`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const QuestButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Nodata = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 20px;
  background-color: #22254a;
  display: flex;
  color: #54587b;

  align-items: center;
  justify-content: center;
  flex: 1;
`;

const InProgress = ({ questData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [rewardIdOpen, setRewardIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const referenceCode =
    useSelector(selectAccount)?.accountProfile?.ReferenceCode;

  const [_claimQuest, { data, isSuccess, isError, error, isLoading }] =
    useClaimQuestPointsMutation();

  const [isTelegramVerified, setIsTelegramVerified] = useState(false);

  const {
    data: profileData,
    error: profileError,
    isError: profileIsError,
    isSuccess: profileIsSuccess,
    refetch,
    isFetching,
  } = useGetAccountProfileQuery();

  console.log(profileData);

  useEffect(() => {
    if (profileIsSuccess) {
      setIsTelegramVerified(profileData.Profile?.IsTelegramVerified);
      dispatch(setAccountProfile(profileData));
    }
  }, [profileData, profileIsError, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      handleRewardModal();
      // refetch();
    }

    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const handleRuleModal = (id) => {
    setIsOpen(true);

    setModalData([
      {
        GeneralRuleKey: questData?.filter(
          (data) => data.AccountQuestId === id
        )[0].GeneralRuleKey,
        RewardKey: questData?.filter((data) => data.AccountQuestId === id)[0]
          .RewardKey,
      },
    ]);
  };

  const handleRewardModal = (id) => {
    setRewardIsOpen(true);
  };

  const handleClaim = (id) => {
    console.log(id);
    // handleRewardModal();

    _claimQuest({
      body: {
        AccountQuestId: id,
      },
    });
  };

  // const test = () => {
  //   _claimQuest({
  //     body: {
  //       AccountQuestId: 74,
  //     },
  //   });
  // };

  const handleRouteKey = (route) => {
    switch (route) {
      case "Setting":
        return window.open(
          `${window.location.origin}/v2/invite/?invites=${referenceCode}`,
          "_blank"
        );
      case "AddMining":
        return navigate(`${routes.addPackage_v2}`);
      case "MiningTutorial":
        return navigate(`${routes.mining_v2}/?questhubtutorial=mining`);
      case "AlgoTutorial":
        return navigate(`${routes.algo_v2}/?questhubtutorial=algo`);

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Container>
        {questData.length > 0 ? (
          <QuestWrapper>
            <QuestContainer>
              {questData?.map((data) => (
                <QusetCard key={data.AccountQuestId}>
                  <QuestCardTitleWrapper>
                    <QuestCardTitle>
                      <Lang
                        datakey={data.TitleKey}
                        defaultText={data.TitleKey}
                      />
                    </QuestCardTitle>
                    <QusetCardInfoIcon
                      onClick={() => handleRuleModal(data.AccountQuestId)}
                    >
                      <Icons.Tooltip />
                    </QusetCardInfoIcon>
                  </QuestCardTitleWrapper>
                  <QuesetCardContent>
                    <Lang
                      datakey={data.ContentKey}
                      defaultText={data.ContentKey}
                    />
                  </QuesetCardContent>
                  <ProgressRewardWrapper>
                    <ProgressWrapper>
                      <ProgressContainer>
                        {Array.from({ length: data.TotalProgress }, (v, i) => (
                          <Progress
                            status={i + 1 <= data.FinsishedProgress}
                            key={i}
                          ></Progress>
                        ))}
                      </ProgressContainer>
                      <ProgressNum>
                        ( {data.FinsishedProgress} / {data.TotalProgress} )
                      </ProgressNum>
                    </ProgressWrapper>
                    <QuestCardRewardWrapper>
                      <QuestCardReward>
                        <QuestCardRewardPoint>
                          {data.Points}
                        </QuestCardRewardPoint>
                        AVA Coins
                      </QuestCardReward>
                      <QuestButton
                        type={data.Status}
                        onClick={() =>
                          data.Status === "Finished"
                            ? handleClaim(data.AccountQuestId)
                            : handleRouteKey(data.RouteKey)
                        }
                      >
                        <QuestButtonText>
                          {(() => {
                            switch (data.Status) {
                              case "Waiting":
                                return "GO";
                              case "Finished":
                                return "Claim";
                            }
                          })()}
                        </QuestButtonText>
                      </QuestButton>
                    </QuestCardRewardWrapper>
                  </ProgressRewardWrapper>
                </QusetCard>
              ))}

              {/* <QusetCard>
                <QuestCardTitleWrapper>
                  <QuestCardTitle> Invite 3 Friends !</QuestCardTitle>
                  <QusetCardInfoIcon
                    onClick={() => handleRuleModal(data.AccountQuestId)}
                  >
                    <Icons.Tooltip />
                  </QusetCardInfoIcon>
                </QuestCardTitleWrapper>
                <QuesetCardContent>
                  Invite three friends to sign up and receive AVA Coin
                </QuesetCardContent>
                <ProgressRewardWrapper>
                  <ProgressWrapper>
                    <ProgressContainer>
                      {Array.from({ length: 3 }, (v, i) => (
                        <Progress status={i + 1 <= 3} key={i}></Progress>
                      ))}
                    </ProgressContainer>
                    <ProgressNum>( 3 / 3 )</ProgressNum>
                  </ProgressWrapper>
                  <QuestCardRewardWrapper>
                    <QuestCardReward>
                      <QuestCardRewardPoint>{100}</QuestCardRewardPoint>
                      AVA Coins
                    </QuestCardReward>
                    <QuestButton type={"Finished"} onClick={handleRewardModal}>
                      <QuestButtonText>Claim</QuestButtonText>
                    </QuestButton>
                  </QuestCardRewardWrapper>
                </ProgressRewardWrapper>
              </QusetCard> */}
            </QuestContainer>
          </QuestWrapper>
        ) : (
          <Nodata>
            {isTelegramVerified ? (
              <Lang datakey="nodata" defaultText="No data available" />
            ) : (
              <Lang
                datakey="questHub_noTg"
                defaultText="Before proceeding, please complete the Telegram verification."
              />
            )}
          </Nodata>
        )}
      </Container>
      {rewardIdOpen && (
        <RewardModal setRewardIsOpen={setRewardIsOpen} refetchCoin={refetch} />
      )}
      {isOpen && <RuleModal data={modalData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default InProgress;
