import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Icons from "../../global/icons";

import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import { useState } from "react";
import { useEffect } from "react";
import MiningBarChart from "./MiningBarChart";
import MiningBarChartTest from "./MiningBarChartTest";

import StackedBarChart from "./StackedBarChart";

import Lang from "../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  padding: 40px;
  height: auto;
  max-height: 95vh;
  /* padding: 2.088vw 4.166vw; */
  width: 41.66vw;
  max-width: 800px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TableWrapper = styled.div`
  border: 1px solid rebeccapurple;

  ${RWD_MD(css`
    overflow-x: auto;
  `)};
`;

const TableContainer = styled.div`
  overflow-y: auto;
  height: 20vh;
  overscroll-behavior: contain;

  ${RWD_MD(css`
    max-height: 50vh;
    min-width: 400px;
  `)};
`;

const TableHeaderWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #151944;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    padding: 5px;
    min-width: 400px;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

const TableItemWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${(props) => (props.index % 2 ? "" : "#D9D9D9")};
`;

const TableItem = styled.div`
  /* width: 100%; */
`;

const TableItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: min(max(0.833vw, 14px), 16px);
`;

const Nodata = styled.div`
  text-align: center;
  /* font-weight: bold; */
  font-size: 20px;
  padding: 20px;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
`;

const ChartWrapper = styled.div`
  padding-bottom: 0px;
`;

const MiningHistory = ({
  isOpen,
  data,
  date,
  returnType,
  handleOpen,
  transferUsdt,
  initData,
}) => {
  const [tableData, setTableData] = useState([]);
  const [selectDate, setSelectDate] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectDate("");
    }
  }, [isOpen]);

  useEffect(() => {
    setTableData(
      date
        .map((day, index) => {
          const total = data
            .map((profit) => {
              return profit.HistoryProfits[index] !== 0
                ? profit.HistoryProfits[index]
                : transferUsdt(profit.Target, profit.Profits[index]);
            })
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          // .toFixed(2);
          return {
            EndDate: day,
            Volume: total,
            ReturnType: returnType[index],
            DateId: index,
          };
        })
        .reverse()
    );
  }, [data, date]);

  const tableHeader = [
    {
      id: 1,
      name: <Lang datakey="period" defaultText="Period" />,
      value: "Period",
      width: "10%",
    },
    {
      id: 2,
      name: <Lang datakey="endDate" defaultText="EndDate" />,
      value: "EndDate",
      width: "20%",
    },
    {
      id: 3,
      name: (
        <Lang datakey="mined_volume" defaultText="Mined Volume (StableCoin)" />
      ),
      value: "Volume",
      width: "45%",
    },
    {
      id: 4,
      name: <Lang datakey="return_type" defaultText="ReturnType" />,
      value: "ReturnType",
      width: "25%",
    },
  ];

  // const chartLabel = data.map((item) => item.Target);
  // const chartData = data.map((item) => {
  //   const Target = item.Target;
  //   const Profit = item.Profits;
  //   return {
  //     Volume: Profit.map((el) => handleExrate(Target, el)).reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       0
  //     ),
  //   };
  // });

  // const chartData = {
  //   Date: date,

  // }

  const handleSelectDate = (date, id) => {
    setSelectDate([date, id]);
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang datakey="mining_history" defaultText="Mining History" />
        </Title>
        <CloseIcon onClick={() => handleOpen(false)}>
          <Icons.Close />
        </CloseIcon>
        {data && (
          <ChartWrapper>
            <MiningBarChartTest
              chartData={data}
              initData={initData}
              date={date}
              selectDate={selectDate}
              transferUsdt={transferUsdt}
            />
          </ChartWrapper>
        )}

        {tableData.length > 0 ? (
          <TableWrapper>
            <TableHeaderWrapper>
              {tableHeader.map((item) => (
                <Th
                  key={item.id}
                  style={{
                    width: `${item.width}`,
                  }}
                >
                  {item.name}
                </Th>
              ))}
            </TableHeaderWrapper>
            <TableContainer>
              {tableData.map((item, index) => (
                <TableItemWrapper
                  key={index}
                  index={index}
                  onClick={() => handleSelectDate(item.EndDate, item.DateId)}
                >
                  {/* {console.log(
                    tableData
                      .map((data) => data.Volume)
                      ?.reduce(
                        (accumulator, currentValue) =>
                          Number(accumulator) + Number(currentValue),
                        0
                      )
                  )} */}
                  {tableHeader.map((el) => (
                    <TableItem
                      style={{
                        width: `${el.width}`,
                      }}
                      key={el.id}
                    >
                      <TableItemText>
                        {(() => {
                          switch (el.value.toLocaleLowerCase()) {
                            case "period":
                              return tableData.length - index;
                            case "returntype":
                              switch (item[el.value]) {
                                case "no":
                                  return "CP";
                                case "all":
                                  return "WP";
                                case "interest":
                                  return "WMT";
                                case "avapoint":
                                  return "MAP";
                              }
                            default:
                              return item[el.value];
                          }
                        })()}
                      </TableItemText>
                    </TableItem>
                  ))}
                </TableItemWrapper>
              ))}
            </TableContainer>
          </TableWrapper>
        ) : (
          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}
      </Container>
    </Wrapper>
  );
};

export default MiningHistory;
