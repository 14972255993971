import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { handleColorType } from "../../utils/ButtonStyle";

import { useSelector } from "react-redux";
import { selectAccount } from "../../slices/accountSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";

// steps
import TelegramStep from "./VerifiedStep/TelegramStep";
import WithdrawalStep from "./VerifiedStep/WithdrawalStep";
import ApikeyStep from "./VerifiedStep/ApikeyStep";

import ApiKeyPermissionModal from "../ApiKeyPermissionModal";
import Loading from "../Loading/Loading";
import TaskCompleted from "./TaskCompleted";

// api
import { useGetAccountProfileQuery } from "../../services/accountApi";
import { useVerifyTelegramMutation } from "../../services/authApi";
import { useVerifyWithdrawlMutation } from "../../services/authApi";
import { useSetAccountWithdrawalMutation } from "../../services/accountApi";
import { useSetAccountApikeyMutation } from "../../services/accountApi";

import Icons from "../../global/icons";

// alert
import { SuccessMySwal, ErrorMySwal, FooterMySwal } from "../../global/MySwal";

// 多國語言
import { LangString } from "../../utils";
import { getLanguage } from "../../services/common";

// check tag
import { setOnboarding } from "../../services/common";

// lottie
import Lottie from "lottie-react";
import completedAnimation from "../../lotties/completed.json";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  /* color: #151944; */
`;

const Container = styled.div`
  width: 60vw;
  height: 90vh;
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  ${RWD_MD(css`
    width: 75vw;
  `)};

  ${RWD_SM(css`
    width: 90vw;
    height: 95vh;
  `)};

  /* border-radius: 10px; */
`;

const Title = styled.div`
  text-align: center;
  font-size: clamp(28px, 1.875vw, 36px);
  font-family: Montserrat;

  padding-bottom: 35px;
  font-weight: bold;
  background: linear-gradient(to right, #0aecd1 0%, #007adf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MissionWrapper = styled.div`
  width: auto;
`;

const MissionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
`;

const Mission = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;

  /* width: 200px; */
  /* width: 80px;
  height: 80px; */
`;

const MissonNum = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: clamp(14px, 1.145vw, 22px);
  padding-bottom: 2px;
  z-index: 5;
  color: #fcfcfc;
  font-family: Montserrat;
  color: ${({ $status }) => ($status ? "#fcfcfc" : "#cefbf8")};
`;

const MissionBox = styled.div`
  position: relative;
`;

const MissionShape = styled.div`
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );

  width: clamp(75px, 6.77vw, 130px);
  height: clamp(75px, 6.77vw, 130px);
  cursor: pointer;

  /* border: 1px solid; */
  /* background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  background: linear-gradient(to right, #0aecd1 0%, #007adf 100%); */

  /* border-color: rgba(255, 255, 255, 0.15); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin: 50px auto; */
  /* background-color: #3a3d5a;  */

  background: ${({ $status }) =>
    $status
      ? "linear-gradient(to right, #0aecd1 0%, #007adf 100%)"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.3) 136.58%)"};

  /* &::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    right: 50px;
    border-width: 43.3px 25px;
    border-style: solid;
    border-color: ${({ $status }) =>
    $status
      ? "transparent #0aecd1 transparent transparent"
      : "transparent #3a3d5a transparent transparent"};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 50px;
    border-width: 43.3px 25px;
    border-style: solid;
    top: 0;
    border-color: ${({ $status }) =>
    $status
      ? " transparent transparent transparent #0aecd1"
      : " transparent transparent transparent #3a3d5a"};
  } */
`;

const MissionName = styled.div`
  padding-top: 10px;
  /* font-size: min(max(0.833vw, 14px), 16px); */
  font-size: clamp(12px, 1.0416vw, 20px);
  text-align: center;
  color: ${({ $status }) => ($status ? "#0aecd1" : "#cefbf8")};
`;

const MissionIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: clamp(30px, 3.125vw, 60px);
  height: clamp(30px, 3.125vw, 60px);
  /* border: 1px solid red; */

  svg {
    /* border: 1px solid pink; */
    width: clamp(30px, 3.125vw, 60px);
    height: clamp(30px, 3.125vw, 60px);
  }
  & path {
    fill: ${({ $status }) => ($status ? "#fcfcfc" : "#cefbf8")};
  }
`;

const MissionCheck = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  bottom: -5px;
  right: -5px;
  border: 1px solid #cefbf8;
  border-radius: 50%;
  z-index: 10;

  svg {
    width: clamp(26px, 2.6vw, 50px);
    height: clamp(26px, 2.6vw, 50px);
  }

  & path {
    fill: #cefbf8;
  }
`;

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

const SloganWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  font-family: Montserrat;

  /* width: 100%; */
`;

const Slogan = styled.div`
  font-size: clamp(36px, 4.1667vw, 80px);
  font-weight: bold;
  background: linear-gradient(to right, #0aecd1 0%, #007adf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 10px;
`;

const Description = styled.div`
  color: #d3d3d3;
`;

const ActionButtonWrapper = styled.div`
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-bottom: 40px;
  `)};
`;

const ActionButton = styled.button`
  border-radius: 60px;
  min-width: 125px;
  /* width: 140px; */
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;

  ${RWD_LG(css`
    min-width: 110px;
  `)};
`;

const ActionButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 10px 20px;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const SkipButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  right: 0;
  bottom: 20px;
  cursor: pointer;
  /* width: 110px; */
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 60px;
  font-size: min(max(1.0416vw, 16px), 20px);
  padding: 10px 20px;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    bottom: 40px;
  `)};
`;

const SkipIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  /* border: 1px solid red; */
  & path {
    stroke: rgba(255, 255, 255, 0.8);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${RWD_SM(css`
    width: 15px;
    height: 15px;

    svg {
      width: 15px;
      height: 15px;
    }
  `)};
`;

const CompletedWrapper = styled.div``;

const CompletedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
`;

const Complete = styled.div`
  font-size: min(max(1.875vw, 28px), 36px);
  padding: 10px 0;
  text-align: center;

  ${RWD_SM(css`
    font-size: 22px;
  `)};
`;

const CompletedIcon = styled.div`
  display: flex;
  align-items: center;
  width: clamp(150px, 10vw, 200px);
  height: clamp(150px, 10vw, 200px);

  /* & path {
    fill: #1cac2a;
  }

  svg {
    width: 100px;
    height: 100px;
  } */
`;

const CompletedDescription = styled.div`
  color: #d3d3d3;

  text-align: center;
  padding: 10px 0;
`;

const AllCompleted = () => {
  return (
    <CompletedWrapper>
      <CompletedContainer>
        <CompletedIcon>
          {/* <Icons.Success /> */}
          <Lottie animationData={completedAnimation} loop={true} />
        </CompletedIcon>
        <Complete>Everything is prepared now.</Complete>
        <Complete>
          It’s time to enjoy your experience on the AVA platform!
        </Complete>
      </CompletedContainer>
    </CompletedWrapper>
  );
};

const Onboarding = ({
  setOnboardingIsOpen,
  refetchProfile,
  refetchApikey,
  profileIsFetching,
  apikeyIsFetching,
}) => {
  const navigate = useNavigate();
  const userProfile = useSelector(selectAccount).accountProfile;
  const userApikey = useSelector(selectAccount).accountApikey;

  const [missionStatus, setMissionStatus] = useState([]);

  useEffect(() => {
    // 確定三項狀態
    // tg =>  IsTelegramVerified
    // withdrawal => Withdrawal
    // api key => userApikey length
    setMission([
      {
        id: 1,
        name: "Telegram",
        status: userProfile?.IsTelegramVerified,
        icon: <Icons.Telegram />,
      },
      {
        id: 2,
        name: "Withdrawal Address",
        status: !(
          userProfile?.Withdrawal === null || userProfile?.Withdrawal === ""
        ),
        icon: <Icons.Withdawal />,
      },
      // {
      //   id: 3,
      //   name: "API Key",
      //   status: userApikey?.length > 0,
      //   icon: <Icons.Key />,
      // },
    ]);

    setMissionStatus([
      userProfile?.IsTelegramVerified,
      !(userProfile?.Withdrawal === null || userProfile?.Withdrawal === ""),
      userApikey?.length > 0,
    ]);
  }, [userProfile, userApikey]);

  useEffect(() => {
    // 每一個都沒有通過 首頁
    if (missionStatus.every((data) => !data)) {
      setActiveStep(0);
      // console.log(missionStatus.every((data) => data));
    } else {
      // 部分有通過 跳頁到該頁
      if (missionStatus[0] && missionStatus[1] && !missionStatus[2]) {
        handleOnboardingClose();
      }
      setActiveStep(missionStatus.indexOf(false) + 1);
    }
  }, [missionStatus]);

  const [activeStep, setActiveStep] = useState(0);
  const [withdrawalIsVerified, setWithdrawalIsVerified] = useState(false);
  const [mission, setMission] = useState([]);
  const [apiKeyPermissionModalIsOpen, setApiKeyPermissionModalIsOpen] =
    useState(false);

  // 驗證Telegram
  const [tgVerifyCode, setTgVerifyCode] = useState("");
  const [
    _verifyTelegram,
    {
      data: telegramData,
      error: telegramError,
      isSuccess: telegramIsSuccess,
      isError: telegramIsError,
    },
  ] = useVerifyTelegramMutation();

  const handleVerifyTelegram = async () => {
    await _verifyTelegram({
      body: {
        Account: userProfile?.Account,
        VerifyCode: tgVerifyCode,
      },
    });
  };

  useEffect(() => {
    if (telegramIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          // 如果三項都Clear step 變成 3
          console.log(missionStatus[1] && missionStatus[2]);
          if (missionStatus[1] && missionStatus[2]) setActiveStep(3);
          refetchProfile();
        }
      });
    }

    if (telegramIsError) {
      ErrorMySwal({
        title:
          telegramError?.data?.ResultLanguages[getLanguage()] ||
          telegramError?.data?.Message,
      });
    }
  }, [telegramData, telegramIsError]);

  // 驗證withdrawl
  const [withdrawlVerifyCode, setWithdrawalVerifyCode] = useState("");
  const [
    _verifytWithdrawal,
    {
      data: verifytWithdrawalData,
      isSuccess: verifytWithdrawalIsSuccess,
      isError: verifytWithdrawallIsError,
      error: verifytWithdrawalError,
    },
  ] = useVerifyWithdrawlMutation();

  const handleVerifyWidthdrawl = async () => {
    await _verifytWithdrawal({
      body: {
        VerifyCode: withdrawlVerifyCode,
      },
    });
  };

  useEffect(() => {
    if (verifytWithdrawalIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          setWithdrawalIsVerified(true);
        }
      });
    }

    if (verifytWithdrawallIsError) {
      ErrorMySwal({
        title:
          verifytWithdrawalError?.data?.ResultLanguages[getLanguage()] ||
          verifytWithdrawalError?.data?.Message,
      });
    }
  }, [verifytWithdrawalData, verifytWithdrawallIsError]);

  // 設定withdrawl
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [
    _updateWithdrawal,
    {
      data: withdrawalData,
      isSuccess: withdrawalIsSuccess,
      isError: withdrawallIsError,
      error: withdrawalError,
    },
  ] = useSetAccountWithdrawalMutation();

  const handleUpdateWithdrawal = async () => {
    await _updateWithdrawal({
      body: {
        WithdrawalAddress: withdrawalAddress,
      },
    });
  };

  useEffect(() => {
    if (withdrawalIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          // 如果三項都Clear step 變成 3
          console.log(missionStatus[0] && missionStatus[2]);
          if (missionStatus[0] && missionStatus[2]) setActiveStep(3);
          refetchProfile();
        }
      });
    }

    if (withdrawallIsError) {
      ErrorMySwal({
        title:
          withdrawalError?.data?.ResultLanguages[getLanguage()] ||
          withdrawalError?.data?.Message,
      });
    }
  }, [withdrawalData, withdrawallIsError]);

  //設定apikey
  const [exchangeId, setExchangeId] = useState(2); // 預設帶Bybit
  const [apiKey, setApiKey] = useState("");
  const [secretkey, setSecretkey] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const [
    _updateApikey,
    {
      data: apiKeyData,
      error: apiKeyDataError,
      isError: apiKeyDataIsError,
      isSuccess: apiKeyDataIsSuccess,
    },
  ] = useSetAccountApikeyMutation();

  const handleUpdateApiKey = async () => {
    await _updateApikey({
      body: {
        ExchangeId: exchangeId,
        Apikey: apiKey,
        Secretkey: secretkey,
        Account: account,
        Password: password,
      },
    });
  };

  useEffect(() => {
    if (apiKeyDataIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          // 如果三項都Clear step 變成 3
          if (missionStatus[0] && missionStatus[1]) setActiveStep(3);
          refetchApikey();
        }
      });
    }

    if (apiKeyDataIsError) {
      // ResultCode 2184 為 bybit 是 UTA 帳戶錯誤訊息
      FooterMySwal({
        title:
          apiKeyDataError?.data?.ResultLanguages[getLanguage()] ||
          apiKeyDataError?.data?.Message,
        confirmButtonText: "確認",
        footerText:
          apiKeyDataError?.data?.ResultCode === 2184
            ? "Click me to see the tutorial"
            : "Click me to see Auth setting",
        footerFun:
          apiKeyDataError?.data?.ResultCode === 2184
            ? handleBybitUtaTutorial
            : handleApiKeyPermissionModal,
      });
    }
  }, [apiKeyData, apiKeyDataIsError]);

  const handleApiKeyPermissionModal = () => {
    setApiKeyPermissionModalIsOpen(true);
  };

  const handleAction = () => {
    switch (activeStep) {
      case 0:
        return setActiveStep(activeStep + 1);
      case 1:
        if (mission[0]?.status) {
          return setActiveStep(activeStep + 1);
        } else {
          return handleVerifyTelegram();
        }

      case 2:
        if (mission[1]?.status) {
          return missionStatus.every((data) => data)
            ? setActiveStep(3)
            : setActiveStep(missionStatus.indexOf(false) + 1);
        } else {
          if (withdrawalIsVerified) return handleUpdateWithdrawal();
          return handleVerifyWidthdrawl();
        }

      // case 3:
      //   if (mission[2]?.status) {
      //     return missionStatus.every((data) => data)
      //       ? setActiveStep(4)
      //       : setActiveStep(missionStatus.indexOf(false) + 1);
      //   } else {
      //     return handleUpdateApiKey();
      //   }

      case 3:
        return handleOnboardingClose();
    }
  };

  const checkisValid = () => {
    switch (activeStep) {
      case 0:
      case 3:
        return true;
      case 1:
        if (mission[0]?.status) {
          return true;
        } else {
          return tgVerifyCode.trim() !== "";
        }

      case 2:
        if (mission[1]?.status) {
          return true;
        } else {
          if (withdrawalIsVerified) {
            console.log(withdrawalAddress.trim() !== "");
            if (withdrawalAddress.trim() !== "") {
              //  TRC-20 代幣地址的 Regex：
              // ^T[1-9A-HJ-NP-Za-km-z]{33}$
              // BEP-20 代幣地址的 Regex：
              // ^0x[0-9a-fA-F]{40}$
              const Regex = /^0x[0-9a-fA-F]{40}$/;
              return Regex.test(withdrawalAddress.trim());
            } else {
              return false;
            }
          } else {
            return withdrawlVerifyCode.trim() !== "";
          }
        }

      // case 3:
      //   if (mission[2]?.status) {
      //     return true;
      //   } else {
      //     return exchangeId === 4
      //       ? apiKey.trim() !== "" &&
      //           account.trim() !== "" &&
      //           password.trim() !== ""
      //       : apiKey.trim() !== "" && secretkey.trim() !== "";
      //   }

      default:
        break;
    }
  };

  const handleOnboardingClose = () => {
    setOnboardingIsOpen(false);
    setOnboarding("checked");
  };

  const handleBybitUtaTutorial = () => {
    window.open(
      `${window.location.origin}${routes.ApiKeyBybitUta_v2}`,
      "_blank"
    );
  };

  return (
    <Wrapper>
      {(profileIsFetching || apikeyIsFetching) && <Loading />}

      <Container>
        <Title>
          {(() => {
            switch (activeStep) {
              case 0:
                return "WELCOME TO AVA";

              case 1:
                return "Verify Telegram ID";

              case 2:
                return "Update Withdrawal Address";

              // case 3:
              //   return "Update API Key";

              case 3:
                return "CONGRATULATIONS!";

              default:
                return "WELCOME TO AVA";
            }
          })()}
        </Title>

        <MissionWrapper>
          <MissionContainer>
            {mission.map((data) => (
              <Mission key={data.id}>
                <MissionBox>
                  <MissionShape
                    $status={data.status}
                    onClick={() => setActiveStep(data.id)}
                  >
                    <MissonNum $status={data.status}>0{data.id}</MissonNum>
                    <MissionIcon $status={data.status}>{data.icon}</MissionIcon>
                  </MissionShape>
                  {data.status && (
                    <MissionCheck>
                      <Icons.Check />
                    </MissionCheck>
                  )}
                </MissionBox>

                <MissionName $status={data.status}>{data.name}</MissionName>
              </Mission>
            ))}
          </MissionContainer>
        </MissionWrapper>
        {mission.length > 0 && (
          <StepContentWrapper>
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <SloganWrapper>
                      <Slogan>WHAT'S YOUR PROFILE ?</Slogan>
                      <Description>
                        Complete data, Let AVA Platform Bring You the Ultimate
                        Experience.
                      </Description>
                    </SloganWrapper>
                  );

                case 1:
                  if (mission[0]?.status)
                    return <TaskCompleted key={"telegram"} />;
                  return <TelegramStep setVerifyCode={setTgVerifyCode} />;

                case 2:
                  if (mission[1]?.status)
                    return <TaskCompleted key={"withdrawal"} />;
                  return (
                    <WithdrawalStep
                      withdrawalIsVerified={withdrawalIsVerified}
                      withdrawlVerifyCode={withdrawlVerifyCode}
                      setVerifyCode={setWithdrawalVerifyCode}
                      withdrawalAddress={withdrawalAddress}
                      setWithdrawalAddress={setWithdrawalAddress}
                    />
                  );

                // case 3:
                //   if (mission[2]?.status)
                //     return <TaskCompleted key={"apikey"} />;
                //   return (
                //     <ApikeyStep
                //       exchangeId={exchangeId}
                //       setExchangeId={setExchangeId}
                //       setApiKey={setApiKey}
                //       setSecretkey={setSecretkey}
                //       setAccount={setAccount}
                //       setPassword={setPassword}
                //     />
                //   );

                case 3:
                  return <AllCompleted />;
              }
            })()}
          </StepContentWrapper>
        )}

        <ActionButtonWrapper>
          <ActionButton
            type={checkisValid() ? "Waiting" : "disabled"}
            onClick={handleAction}
            disabled={!checkisValid()}
          >
            <ActionButtonText>
              {(() => {
                switch (activeStep) {
                  case 0:
                    return "Start";

                  case 3:
                    return "Confirm";

                  default:
                    return "Continue";
                }
              })()}
            </ActionButtonText>
          </ActionButton>
        </ActionButtonWrapper>

        {activeStep !== 3 && (
          <SkipButton onClick={handleOnboardingClose}>
            <SkipIcon>
              <Icons.Member_Empowerment />
            </SkipIcon>
            Later
          </SkipButton>
        )}
      </Container>
      {apiKeyPermissionModalIsOpen && (
        <ApiKeyPermissionModal
          exchange={exchangeId}
          imgModalIsOpen={apiKeyPermissionModalIsOpen}
          setImgModalIsOpen={setApiKeyPermissionModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default Onboarding;
