import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../../components/Router";
import styled from "styled-components";
import { css } from "styled-components";
import Logo from "../../../images/logo_light.png";
import Icons from "../../global/icons";
import Lang from "../../global/Lang";
import { IoIosArrowDown } from "react-icons/io";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

// 切換語言
import { changeLanguage, selectSystem } from "../../slices/systemSlice";
// 登出
import { removeAuthToken } from "../../services/common";
import { clearAccount } from "../../slices/accountSlice";
import { clearUser } from "../../slices/loginSlice";

import { useSelector } from "react-redux";
import { selectAccount } from "../../slices/accountSlice";

import PWAInstallPrompt from "../../../PWAInstallPrompt";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;

  ${RWD_LG(css`
    align-items: flex-start;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.72vw;
  padding-top: 40px;
  height: auto;
  cursor: pointer;
  padding-bottom: 80px;

  ${RWD_LG(css`
    width: 60px;
    padding-top: 40px;
    padding-bottom: 90px;
  `)};
`;

const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  /* ${RWD_LG(css`
    padding-top: 20px;
  `)}; */
`;

const MenuItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  cursor: pointer;
  position: relative;
  width: 100%;

  ${RWD_LG(css`
    width: 100%;
    padding-bottom: 30px;
  `)};

  ${RWD_SM(css`
    padding-bottom: 30px;
  `)};
`;

const MenuItemList = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const MenuIcon = styled.div`
  width: 20px;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  & path {
    fill: ${(props) => (props.type === "v1" ? "#fcfcfc" : "")};
    fill: ${(props) => (props.type === "fill" ? "#fcfcfc" : "")};
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${RWD_LG(css`
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
    }
  `)};

  ${RWD_SM(css`
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const MenuItem = styled.div`
  color: ${(props) => (props.active ? "#0aecd1" : "#fcfcfc")};
  font-weight: ${(props) => (props.active ? "500" : "300")};
  font-size: clamp(14px, 0.88541667vw, 16.5px);

  ${RWD_LG(css`
    padding-left: 10px;
  `)};

  ${RWD_SM(css`
    font-size: clamp(14px, 0.833vw, 16px);
  `)};
`;

const InnerMenuWrapper = styled.div`
  /* display: flex; */
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  display: none;
  /* ${RWD_LG(css`
    display: flex;
  `)}; */
`;

const InnerMenuTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-right: 10px;
`;

const InnerMenuTitle = styled.div`
  color: #fcfcfc;
  font-size: min(max(1.0416vw, 14px), 20px);
`;

const InnerMenuList = styled.ul`
  padding-left: 25px;
  color: #fcfcfc;
  font-size: min(max(0.9375vw, 16px), 18px);

  height: ${(props) => (props.avapointIsOpen ? "auto" : "0")};

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const InnerMenuItem = styled.li`
  padding-bottom: 20px;
`;

const SlideIcon = styled.div`
  width: 1.5vw;
  height: 1.5vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.open ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;

  svg {
    width: 1.5vw;
    height: 1.5vw;
  }
`;

const HamburgerMenuWrapper = styled.div`
  height: 80px;
  display: none;
  align-items: center;

  ${RWD_LG(css`
    display: flex;
  `)};
`;

const HamburgerMenuContainer = styled.div`
  flex-direction: column;
  align-items: center;
  height: 22px;
  width: 30px;
  display: none;
  justify-content: space-between;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;

  ${RWD_LG(css`
    display: flex;
  `)};
`;

const HamburgerMenuItem = styled.span`
  display: inline-block;
  width: 30px;
  background-color: ${(props) => "white"};
  height: 2px;

  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  &:nth-of-type(1) {
    transition-delay: 0.1s;
    width: 15px;
    align-self: flex-start;
    transform-origin: top;

    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateX(5px) translateY(2px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};
  }

  :nth-of-type(2) {
    transform-origin: center;

    transform: ${(props) =>
      props.hamIsOpen ? "rotate(-45deg)" : "rotate(0deg)"};
  }

  &:nth-of-type(3) {
    transition-delay: 0.3s;
    width: 15px;
    transform-origin: bottom;
    align-self: flex-end;
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateX(-5px) translateY(-1px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};
  }
`;

const CountWrapper = styled.div`
  position: absolute;
  left: 5px;
  bottom: 75%;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: #f05d2f;
`;

const Count = styled.div`
  padding-top: 1px;
  font-family: Montserrat;
  color: #fcfcfc;
  font-size: 10px;
  /* padding: 5px; */
`;

const SubMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 20px;
`;

const SubMenuList = styled.ul`
  display: flex;
  gap: 20px;
  flex-direction: column;
  /* width: 100%; */
  width: calc(100% - 10px);

  border-left: 1px solid rgba(255, 255, 255, 0.1);
`;

const SubMenuItem = styled.li`
  color: #fcfcfc;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 20px;
  transition: all 0.3s;
  color: ${(props) => (props.active ? "#0aecd1" : "rgba(255, 255, 255, 0.5)")};
  &:hover {
    color: #0aecd1;
  }

  ${RWD_LG(css`
    margin-left: 40px;
  `)};

  ${RWD_SM(css`
    margin-left: 30px;
  `)};
`;

const ArrowIcon = styled.div`
  color: rgba(255, 255, 255, 0.2);
  margin-left: auto;
  font-size: 15px;
  transform: ${(props) => (props.open ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
`;

const Aside = ({
  openDepositFun,
  openWithdrawFun,
  openHistoryFun,
  hamIsOpen,
  toggleHamMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const waitingClaimQuests =
    useSelector(selectAccount)?.accountProfile?.WaitingClaimQuests;

  const [menuData, setMenuData] = useState([
    {
      name: "DataCenter",
      path: "datacenter",
      nameLang: <Lang datakey="menu_datacenter" defaultText="DataCenter" />,
      icon: <Icons.DataCenter />,
      active: <Icons.DataCenter_fill />,
      isOpen: false,
      subMenu: [
        {
          name: "Presonal Data",
          nameLang: (
            <Lang
              datakey="datacenter_personaldata"
              defaultText="PersonalData"
            />
          ),
          path: "datacenter?tab=personaldata",
        },
        {
          name: "AvaData",
          nameLang: <Lang datakey="datacenter_avadata" defaultText="AvaData" />,
          path: "datacenter?tab=avadata",
        },
        {
          name: "NewsData",
          nameLang: (
            <Lang datakey="datacenter_newsdata" defaultText="NewsData" />
          ),
          path: "datacenter?tab=newsdata",
        },
        // {
        //   name: "MarketData",
        //   nameLang: (
        //     <Lang datakey="datacenter_marketdata" defaultText="MarketData" />
        //   ),
        //   path: "datacenter?tab=marketdata",
        // },
      ],
    },
    {
      name: "QuestHub",
      path: "questhub",
      nameLang: <Lang datakey="menu_questhub" defaultText="QuestHub" />,
      icon: <Icons.Questhub_Rocket />,
      active: <Icons.Questhub_Rocket_fill />,
      isOpen: false,
    },
    {
      name: "Mining",
      path: "mining",
      nameLang: <Lang datakey="menu_mining" defaultText="Mining" />,
      icon: <Icons.Mining />,
      active: <Icons.Mining_fill />,
      isOpen: false,
    },
    // {
    //   name: "AlgoTrade",
    //   path: "algo",
    //   nameLang: <Lang datakey="menu_algotrade" defaultText="AlgoTrade" />,
    //   icon: <Icons.AlgoTrade />,
    //   active: <Icons.AlgoTrade_fill />,
    //   isOpen: false,
    // },
    // {
    //   name: "Memberships",
    //   path: "/AVA/dashboard/memberships",
    //   icon: <Icons.Membership />,
    //   active: <Icons.Membership />,
    //   type: "v1",
    // },
    {
      name: "Member",
      path: "member",
      nameLang: <Lang datakey="menu_member" defaultText="Member" />,
      icon: <Icons.Member_Member />,
      active: <Icons.Member_Member_fill />,
      isOpen: false,
    },
    {
      name: "Settings",
      path: "setting",
      nameLang: <Lang datakey="menu_settings" defaultText="Settings" />,
      icon: <Icons.Setting />,
      active: <Icons.Setting_fill />,
      isOpen: false,
    },
  ]);

  const handleNavigate = (link) => {
    toggleHamMenu(false);
    navigate(`${link}`);
  };

  // 頁面挑轉時判斷是否要展開選單
  useEffect(() => {
    setMenuData(
      menuData.map((data) => {
        if (location.pathname.includes(data.path)) {
          return { ...data, isOpen: true };
        }
        return {
          ...data,
          isOpen: false,
        };
      })
    );
  }, [location.pathname]);

  const handleBackToHome = () => {
    toggleHamMenu(false);
    navigate(`${routes.dataCenter_v2}?tab=personaldata`);
  };

  const [avapointIsOpen, setAvapointIsOpen] = useState(false);

  const handleSlideMenu = () => {
    setAvapointIsOpen(!avapointIsOpen);
  };

  const ToggleHamMenu = () => {
    toggleHamMenu(!hamIsOpen);
  };

  const handleToggleSubMenu = (value) => {
    console.log(value);
    setMenuData(
      menuData.map((data) => {
        if (data.name === value) {
          return { ...data, isOpen: !data.isOpen };
        }
        return data;
      })
    );
  };

  return (
    <Wrapper>
      <Container>
        <HamburgerMenuWrapper>
          <HamburgerMenuContainer hamIsOpen={hamIsOpen} onClick={ToggleHamMenu}>
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
          </HamburgerMenuContainer>
        </HamburgerMenuWrapper>
        <LogoWrapper onClick={handleBackToHome}>
          <img src={Logo} />
        </LogoWrapper>
        <MenuWrapper>
          {menuData.map((data, index) => (
            <MenuItemContainer key={index}>
              <MenuItemList
                onClick={() =>
                  data.subMenu
                    ? handleToggleSubMenu(data.name)
                    : handleNavigate(data.path)
                }
              >
                <MenuIcon type={data.type}>
                  {location.pathname.includes(data.path)
                    ? data.subMenu
                      ? data.icon
                      : data.active
                    : data.icon}
                </MenuIcon>
                <MenuItem
                  active={
                    data.subMenu ? false : location.pathname.includes(data.path)
                  }
                >
                  {data.nameLang}
                </MenuItem>
                {data.name === "QuestHub" && waitingClaimQuests > 0 && (
                  <CountWrapper>
                    <Count>{waitingClaimQuests}</Count>
                  </CountWrapper>
                )}
                {data.subMenu && (
                  <ArrowIcon open={data.isOpen}>
                    <IoIosArrowDown />
                  </ArrowIcon>
                )}
              </MenuItemList>

              {data.subMenu && data.isOpen && (
                <SubMenuWrapper>
                  <SubMenuList>
                    {data.subMenu.map((item, index) => (
                      <SubMenuItem
                        key={index}
                        onClick={() => handleNavigate(item.path)}
                        active={location.search.includes(
                          item.path.split(data.path)[1]
                        )}
                      >
                        {item.nameLang}
                      </SubMenuItem>
                    ))}
                  </SubMenuList>
                </SubMenuWrapper>
              )}
            </MenuItemContainer>
          ))}
          <PWAInstallPrompt />
        </MenuWrapper>
        <InnerMenuWrapper>
          <InnerMenuTitleWrapper onClick={handleSlideMenu}>
            <InnerMenuTitle>AVA Points</InnerMenuTitle>
            <SlideIcon open={avapointIsOpen}>
              <Icons.Arrow />
            </SlideIcon>
          </InnerMenuTitleWrapper>
          <InnerMenuList avapointIsOpen={avapointIsOpen}>
            <InnerMenuItem onClick={() => openHistoryFun(true)}>
              Transaction History
            </InnerMenuItem>
            <InnerMenuItem onClick={() => openDepositFun(true)}>
              Deposit
            </InnerMenuItem>
            <InnerMenuItem onClick={() => openWithdrawFun(true)}>
              Withdraw
            </InnerMenuItem>
          </InnerMenuList>
        </InnerMenuWrapper>
      </Container>
    </Wrapper>
  );
};

export default Aside;
