import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useSetAccountProfileMutation,
  useGetAccountProfileQuery,
  useSetAccountApikeyMutation,
  useSetAccountWithdrawalMutation,
  useSetRecommenderMutation,
} from "../../services/accountApi";
import {
  useVerifyTelegramMutation,
  useVerifyWithdrawlMutation,
} from "../../services/authApi";
import { useDispatch } from "react-redux";
import { selectAccount, setAccountProfile } from "../../slices/accountSlice";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";

import styled from "styled-components";
import { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import SettingModal from "./SettingModal";
import Icons from "../../global/icons";
import Lang from "../../global/Lang";

// v1 api
import { API_AvaPointWithdrawal } from "../../../utils/api";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal).mixin({
  confirmButtonColor: "#22254a",
});

import ApiKeyPermissionModal from "../../components/ApiKeyPermissionModal";
import ReferralModal from "../../global/ReferralModal";

import { SuccessMySwal, ErrorMySwal, FooterMySwal } from "../../global/MySwal";
import { LangString } from "../../utils";
import { getLanguage } from "../../services/common";

// react-scroll
import { scroller, Element } from "react-scroll";

// #region styled
const Wrapper = styled.div`
  height: 100%;
  color: #fcfcfc;
`;

const TitleWrapper = styled.div`
  display: flex;
  padding-top: 50px;
  align-items: center;
  padding-bottom: 20px;

  &:first-child {
    padding-top: 0;
  }
`;

const TitleText = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  color: #fcfcfc;
  padding: 0 10px 0 20px;
  text-transform: capitalize;
  position: relative;
  width: 100%;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 18px), 20px);
    padding-left: 10px;
  `)};
`;

const TitleIcon = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;

  ${RWD_SM(css`
    width: 25px;
    height: 25px;
  `)};

  svg {
    width: 30px;
    height: 30px;

    ${RWD_SM(css`
      width: 25px;
      height: 25px;
    `)};
  }

  & path {
    fill: #fcfcfc;
  }
`;

const ItemListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemListContainer = styled.div`
  border-bottom: 1px solid #3a3d5a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;

  ${RWD_MD(css`
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  `)};
`;

const ItemListContent = styled.div`
  width: 50%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  font-size: min(max(0.9375vw, 16px), 18px);

  color: ${(props) => (props.status ? "#F05D2F" : "#FCFCFC")};

  ${RWD_MD(css`
    width: 70%;
    margin: 0;
  `)};

  /* ${RWD_SM(css`
    width: 80%;
  `)}; */
`;

const ItemListContentErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #f05d2f;

    /* fill: wheat; */
    /* stroke: antiquewhite; */
  }
`;

const ItemListContentErrorWrapper = styled.div`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  font-size: min(max(0.9375vw, 16px), 18px);

  display: flex;
  align-items: center;
  color: #f05d2f;

  ${RWD_MD(css`
    width: 70%;
    margin: 0;
  `)};
`;

const ItemListContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${RWD_MD(css`
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ItemListTitle = styled.div`
  width: 20%;
  padding-left: 50px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_XL(css`
    padding-left: 0;
  `)};

  ${RWD_MD(css`
    padding-left: 0;
    width: 100%;
    padding-top: 20px;
    position: relative;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ItemListVerification = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_LG(css`
    width: 20%;
  `)};

  ${RWD_MD(css`
    position: absolute;
    top: 20px;
    right: 0;
    width: auto;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ItemListVerificationIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: ${(props) => (props.verified ? "#1cac2a" : "#54587B")};

    /* fill: wheat; */
    /* stroke: antiquewhite; */
  }
`;

const ItemListVerificationText = styled.div`
  display: flex;
  padding-left: 10px;
`;

const ItemListButtonWrapper = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;

  ${RWD_LG(css`
    width: 20%;
  `)};
`;
const ItemListButton = styled.div`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  cursor: pointer;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding: 0.52vw 1.3vw;

  ${RWD_LG(css`
    padding: 8px 18px;
    width: 90%;
    max-width: 100px;
    /* width: 100%; */
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    padding: 5px 10px;
    width: 100%;

    /* width: 100%; */
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ItemListType = styled.div`
  width: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: min(max(0.9375vw, 16px), 18px);

  /* margin-left: auto; */
`;

const TutorialWrapper = styled.div`
  color: #54587b;
  font-size: 13px;
  position: absolute;
  /* left: 20px; */
  cursor: pointer;
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #54587b;
  }
`;

// #endregion

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectAccount).accountProfile;
  const apikeys = useSelector(selectAccount).accountApikey;
  const [searchParams, setSearchParams] = useSearchParams();

  const handleScrollToTarget = (value) => {
    scroller.scrollTo(value, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -100,
    });
  };

  // 判斷是不是導頁而來
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const paramVerified = query.get("verified"); // 由通知訊息導頁而來
    const paramApiKeyInvalid = query.get("invalid");
    const target = query.get("target");
    const phoneCompleted = query.get("completed");

    if (target) {
      // scroll to target
      handleScrollToTarget(target);
    }

    if (phoneCompleted) {
      handleOpenModal("phone");
    }

    if (paramVerified) {
      // 如果沒有新增任合一個apikey 都請使用者先新增Bybit
      if (paramVerified === "apikey") {
        handleOpenModal(paramVerified, 2);
      } else {
        handleOpenModal(paramVerified);
      }
    } else if (paramApiKeyInvalid) {
      handleOpenModal(
        paramApiKeyInvalid.split("_")[0],
        paramApiKeyInvalid.split("_")[1] === "binance" ? 1 : 2
      );
    } else {
      return;
    }
  }, [search]);

  const {
    data: profileData,
    error,
    isError,
    isSuccess,
    refetch,
    isFetching,
  } = useGetAccountProfileQuery();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAccountProfile(profileData));
    }
  }, [profileData, isSuccess]);

  const [
    _setAccountProfile,
    {
      data: setAccountProfileResponse,
      isSuccess: setAccountProfileIsSuccess,
      isError: setAccountProfileIsError,
      error: setAccountProfileError,
    },
  ] = useSetAccountProfileMutation();

  const [
    _verifytWithdrawal,
    {
      data: verifytWithdrawalResponse,
      isSuccess: verifytWithdrawalIsSuccess,
      isError: verifytWithdrawallIsError,
      error: verifytWithdrawalError,
    },
  ] = useVerifyWithdrawlMutation();

  const [
    _setAccountWithdrawal,
    {
      data: setAccountWithdrawalResponse,
      isSuccess: setAccountWithdrawalIsSuccess,
      isError: setAccountWithdrawalIsError,
      error: setAccountWithdrawalError,
    },
  ] = useSetAccountWithdrawalMutation();

  const [
    _verifyTelegram,
    {
      data: verifyTelegramResponse,
      error: verifyTelegramError,
      isSuccess: verifyTelegramIsSuccess,
      isError: verifyTelegramIsError,
    },
  ] = useVerifyTelegramMutation();

  const [
    _updateApikey,
    {
      data: updateApiKeyData,
      error: updateApiKeyDataError,
      isError: updateApiKeyDataIsError,
      isSuccess: updateApiKeyDataIsSuccess,
    },
  ] = useSetAccountApikeyMutation();

  const [
    _setRecommender,
    {
      data: setRecommenderResponse,
      error: setRecommenderResponseError,
      isError: setRecommenderResponseIsError,
      isSuccess: setRecommenderResponseIsSuccess,
    },
  ] = useSetRecommenderMutation();

  const handleUpdateName = async () => {
    _setAccountProfile({
      body: { Name: name },
    });
  };

  const handleUpdatePassword = async () => {
    _setAccountProfile({
      body: {
        OldPassword: oldPassword,
        NewPassword: newPassword,
      },
    });
  };

  const handleUpdatePhone = async () => {
    _setAccountProfile({
      body: {
        Phone: `+${countryCode}${
          phone.startsWith("0") ? phone.slice(1) : phone
        }`,
      },
    });
  };

  const handleVerifyTelegram = async () => {
    _verifyTelegram({
      body: {
        Account: data.Account,
        VerifyCode: telegramId,
      },
    });
  };

  const handleUpdateApiKey = async (id) => {
    _updateApikey({
      body: {
        ExchangeId: id,
        Apikey: newApikey,
        Secretkey: newSecretkey,
        Account: newIgAccount,
        Password: newIgPassword,
      },
    });
  };

  const handleWithdrawal = async () => {
    _setAccountWithdrawal({
      body: {
        WithdrawalAddress: withdrawal,
      },
    });
  };

  const handleVerifyWidthdrawl = async () => {
    _verifytWithdrawal({
      body: {
        VerifyCode: withdrawlVerifyCode,
      },
    });
  };

  const handleSetRecommender = async () => {
    _setRecommender({
      body: {
        ReferenceCode: referanceCode,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);

    setName("");
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setPhone("");
    setTelegramId("");
    setReferanceCode("");
    setWithdrawal("");
    setWithdrawalVerifyCode("");

    // 清除url query
    // searchParams.delete("verified");
    // searchParams.delete("invalid");
    // searchParams.delete("completed");
    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
      params.delete(key);
    });
    setSearchParams(params);
  };

  const [account, setAccount] = useState(data?.Account ?? "");
  const [name, setName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");

  const [open, setOpen] = useState(false); // password dialog
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [referanceCode, setReferanceCode] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [withdrawal, setWithdrawal] = useState("");

  const [withdrawlVerifyCode, setWithdrawalVerifyCode] = useState("");

  const [newApikey, setNewApikey] = useState("");
  const [newSecretkey, setNewSecretkey] = useState("");
  // ig
  const [newIgAccount, setNewIgAccount] = useState("");
  const [newIgPassword, setNewIgPassword] = useState("");

  const [apiKey, setApiKey] = useState("");
  const [exchange, setExchange] = useState("");

  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);

  const [referralModalIsOpen, setReferralModalIsOpen] = useState(false);

  useEffect(() => {
    if (!data) return;
    setAccount(data.Account);
    setCountryCode(data?.Phone?.slice(1, 4));

    // apikey
    const Binance = apikeys.filter((data) => data.ExchangeId === 1)[0] ?? {
      ExchangeId: 1,
    };
    const Bybit = apikeys.filter((data) => data.ExchangeId === 2)[0] ?? {
      ExchangeId: 2,
    };

    const Ig = apikeys.filter((data) => data.ExchangeId === 4)[0] ?? {
      ExchangeId: 4,
    };
    setApiKey({ Binance: Binance, Bybit: Bybit, Ig: Ig });
  }, [data, apikeys]);

  // profile
  useEffect(() => {
    if (setAccountProfileIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
      handleClose();
      // setOpen(false);
    }

    if (setAccountProfileIsError) {
      ErrorMySwal({
        title:
          setAccountProfileError?.data?.ResultLanguages[getLanguage()] ||
          setAccountProfileError?.data?.Message,
      });
    }
  }, [setAccountProfileResponse, setAccountProfileIsError]);

  // apikey
  useEffect(() => {
    if (updateApiKeyDataIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
      setOpen(false);
    }
    if (updateApiKeyDataIsError) {
      // ResultCode 2184 為 bybit 是 UTA 帳戶錯誤訊息
      FooterMySwal({
        title:
          updateApiKeyDataError?.data?.ResultLanguages[getLanguage()] ||
          updateApiKeyDataError?.data?.Message,
        confirmButtonText: "確認",
        footerText:
          updateApiKeyDataError?.data?.ResultCode === 2184
            ? "Click me to see the tutorial"
            : "Click me to see Auth setting",
        footerFun:
          updateApiKeyDataError?.data?.ResultCode === 2184
            ? handleBybitUtaTutorial
            : handleImgModal,
      });
    }
  }, [updateApiKeyData, updateApiKeyDataIsError]);

  const handleImgModal = () => {
    setImgModalIsOpen(true);
  };

  const handleBybitUtaTutorial = () => {
    navigate("/v2/apikeyguide/bybituta");
  };

  const handleTermsOfService = () => {
    navigate("/v2/termsofservice");
  };

  // telegram
  useEffect(() => {
    if (verifyTelegramIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });

      setOpen(false);
      refetch();
    }

    if (verifyTelegramIsError) {
      ErrorMySwal({
        title:
          verifyTelegramError?.data?.ResultLanguages[getLanguage()] ||
          verifyTelegramError?.data?.Message,
      });
    }
  }, [verifyTelegramResponse, verifyTelegramIsError]);

  // withdrawal
  useEffect(() => {
    if (setAccountWithdrawalIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });

      setOpen(false);
    }

    if (setAccountWithdrawalIsError) {
      ErrorMySwal({
        title:
          setAccountWithdrawalError?.data?.ResultLanguages[getLanguage()] ||
          setAccountWithdrawalError?.data?.Message,
      });
    }
  }, [setAccountWithdrawalResponse, setAccountWithdrawalIsError]);

  // recommender

  useEffect(() => {
    if (setRecommenderResponseIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
      setOpen(false);
    }

    if (setRecommenderResponseIsError) {
      ErrorMySwal({
        title:
          setRecommenderResponseError?.data?.ResultLanguages[getLanguage()] ||
          setRecommenderResponseError?.data?.Message,
      });
    }
  }, [setRecommenderResponse, setRecommenderResponseIsError]);

  useEffect(() => {
    if (verifytWithdrawalIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
      setOpen(false);
      handleOpenModal("withdrawal");
    }

    if (verifytWithdrawallIsError) {
      ErrorMySwal({
        title:
          verifytWithdrawalError?.data?.ResultLanguages[getLanguage()] ||
          verifytWithdrawalError?.data?.Message,
      });
    }
  }, [verifytWithdrawalResponse, verifytWithdrawallIsError]);

  const [settingModalData, setSettingModalData] = useState([]);

  const handleUpdateProfile = (data) => {
    switch (data.type) {
      case "name":
        handleUpdateName();
        break;
      case "password":
        handleUpdatePassword();
        break;
      case "phone":
        handleUpdatePhone();
        break;
      case "referance":
        handleSetRecommender();
        break;
      case "telegram":
        handleVerifyTelegram();
        break;
      case "withdrawalVerify":
        handleVerifyWidthdrawl();
        break;
      case "withdrawal":
        handleWithdrawal();
        break;
      case "apikey":
        handleUpdateApiKey(exchange);
        break;
      default:
        break;
    }
  };

  const handleOpenModal = (type, exchangeId) => {
    setOpen(true);
    setExchange(exchangeId);
    switch (type) {
      case "name":
        setSettingModalData({
          type: type,
          value: [name],
          default: data?.Name,
          funList: [setName],
        });
        break;
      case "password":
        setSettingModalData({
          type: type,
          value: [oldPassword, newPassword, confirmPassword],
          funList: [setOldPassword, setNewPassword, setConfirmPassword],
        });
        break;
      case "phone":
        setSettingModalData({
          type: type,
          value: [countryCode, phone],
          default: data?.Phone?.slice(4),
          funList: [setCountryCode, setPhone],
        });
        break;
      case "referance":
        setSettingModalData({
          type: type,
          value: [referanceCode],
          funList: [setReferanceCode],
        });
        break;
      case "telegram":
        setSettingModalData({
          type: type,
          value: [telegramId],
          funList: [setTelegramId],
        });
        break;
      case "withdrawalVerify":
        setSettingModalData({
          type: type,
          value: [withdrawlVerifyCode],
          funList: [setWithdrawalVerifyCode],
        });
        break;
      case "withdrawal":
        setSettingModalData({
          type: type,
          value: [withdrawal],
          funList: [setWithdrawal],
        });
        break;
      case "apikey":
        setSettingModalData({
          type: type,
          value: apiKey,
          funList: [
            setNewApikey,
            setNewSecretkey,
            setNewIgAccount,
            setNewIgPassword,
          ],
        });
        break;
    }

    return;
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleIcon>
          <Icons.Profile />
        </TitleIcon>
        <TitleText>
          <Element name="profile">
            <Lang datakey="setting_subTitle_profile" defaultText="My Profile" />
          </Element>
        </TitleText>
      </TitleWrapper>

      <ItemListWrapper>
        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_account" defaultText="Account" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{account}</ItemListContent>
          </ItemListContentWrapper>

          {/* <ItemListButton>Edit</ItemListButton> */}
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_name" defaultText="Name" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.Name}</ItemListContent>
            <ItemListButtonWrapper>
              <ItemListButton
                onClick={() => {
                  handleOpenModal("name");
                }}
              >
                <Lang datakey="edit" defaultText="Edit" />
              </ItemListButton>
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_password" defaultText="Password" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>＊＊＊＊＊＊＊＊</ItemListContent>
            <ItemListButtonWrapper>
              <ItemListButton
                onClick={() => {
                  handleOpenModal("password");
                }}
              >
                <Lang datakey="edit" defaultText="Edit" />
              </ItemListButton>
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_phone" defaultText="Mobile Phone" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.Phone}</ItemListContent>
            <ItemListButtonWrapper>
              <ItemListButton
                onClick={() => {
                  handleOpenModal("phone");
                }}
              >
                <Lang datakey="edit" defaultText="Edit" />
              </ItemListButton>
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>AVA Points</ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.AvaPoints}</ItemListContent>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>AVA Coins</ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.AvaFreePoints}</ItemListContent>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_reference" defaultText="Reference Code" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.ReferenceCode}</ItemListContent>
            <ItemListButtonWrapper>
              <ItemListButton
                onClick={() => {
                  setReferralModalIsOpen(true);
                }}
              >
                <Lang datakey="invite" defaultText="Invite" />
              </ItemListButton>
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_recommender" defaultText="Recommender" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>{data?.Recommender}</ItemListContent>
            {data?.ReferralStatus != "0" && (
              <ItemListVerification>
                {/* 判斷有沒有處理成功 */}

                <ItemListVerificationIcon
                  verified={data?.ReferralStatus == "2"}
                >
                  <Icons.Check />
                </ItemListVerificationIcon>
                <ItemListVerificationText>
                  {data?.ReferralStatus == "2" ? (
                    <Lang datakey="verified" defaultText="Verified" />
                  ) : (
                    <Lang datakey="pending" defaultText="Pending" />
                  )}
                </ItemListVerificationText>
              </ItemListVerification>
            )}

            <ItemListButtonWrapper>
              {/* 判斷推薦代碼有沒有處理成功 */}
              {data?.ReferralStatus == "0" && (
                <ItemListButton
                  onClick={() => {
                    handleOpenModal("referance");
                  }}
                >
                  <Lang datakey="edit" defaultText="Edit" />
                </ItemListButton>
              )}
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang datakey="setting_subTitle_level" defaultText="Level" />
          </ItemListTitle>
          <ItemListContentWrapper>
            <ItemListContent>
              LV. {""}
              <Lang datakey={data?.LevelLanguageKey} defaultText="Level" />
            </ItemListContent>
          </ItemListContentWrapper>
        </ItemListContainer>

        <ItemListContainer>
          <ItemListTitle>
            <Lang
              datakey="setting_subTitle_identity"
              defaultText="Identity Verification"
            />
          </ItemListTitle>
          <ItemListContentWrapper>
            {data?.KycDescription ? (
              <ItemListContentErrorWrapper>
                <ItemListContentErrorIcon>
                  <Icons.Tooltip />
                </ItemListContentErrorIcon>
                <ItemListContent status={data?.KycDescription}>
                  {data?.KycDescription}
                </ItemListContent>
              </ItemListContentErrorWrapper>
            ) : (
              <ItemListContent>
                {data?.IsKycVerified ? (
                  <Lang
                    datakey="verification_success"
                    defaultText="Verification Success"
                  />
                ) : (
                  <>
                    {data?.KycId ? (
                      <Lang datakey="verifying" defaultText="Verifying" />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </ItemListContent>
            )}

            <ItemListVerification>
              <ItemListVerificationIcon verified={data?.IsKycVerified}>
                <Icons.Check />
              </ItemListVerificationIcon>
              <ItemListVerificationText>
                <Lang datakey="verified" defaultText="Verified" />
              </ItemListVerificationText>
            </ItemListVerification>
            <ItemListButtonWrapper>
              {/* 有KycId代表審核中，不顯示驗證button 有KycDescription代表審核失敗，要顯示驗證button*/}
              {data?.KycId && !data?.KycDescription
                ? ""
                : !data?.IsKycVerified && (
                    <ItemListButton
                      onClick={() => {
                        navigate(routes.identity_v2);
                      }}
                    >
                      <Lang datakey="verify" defaultText="Verify" />
                    </ItemListButton>
                  )}
            </ItemListButtonWrapper>
          </ItemListContentWrapper>
        </ItemListContainer>
      </ItemListWrapper>

      <TitleWrapper>
        <TitleIcon>
          <Icons.Telegram />
        </TitleIcon>
        <TitleText>
          <Lang
            datakey="setting_subTitle_telegram"
            defaultText="Telegram Verification"
          />
        </TitleText>
      </TitleWrapper>

      <ItemListContainer>
        <ItemListTitle>Telegram ID</ItemListTitle>

        <ItemListContentWrapper>
          <ItemListContent>{data?.TelegramId}</ItemListContent>
          <ItemListVerification>
            <ItemListVerificationIcon verified={data?.IsTelegramVerified}>
              <Icons.Check />
            </ItemListVerificationIcon>
            <ItemListVerificationText>
              <Lang datakey="verified" defaultText="Verified" />
            </ItemListVerificationText>
          </ItemListVerification>
          <ItemListButtonWrapper>
            {!data?.IsTelegramVerified && (
              <ItemListButton
                onClick={() => {
                  handleOpenModal("telegram");
                }}
              >
                <Lang datakey="verify" defaultText="Verify" />
              </ItemListButton>
            )}
          </ItemListButtonWrapper>
        </ItemListContentWrapper>
      </ItemListContainer>

      <TitleWrapper>
        <TitleIcon>
          <Icons.Withdawal />
        </TitleIcon>
        <TitleText>
          <Element name="withdrawal">
            <Lang
              datakey="setting_subTitle_withdrawal"
              defaultText="Withdrawal Address"
            />
          </Element>
          <TutorialWrapper onClick={() => navigate(routes.WithdrawalGuide_v2)}>
            <Lang
              datakey="withdrawal_help_notice"
              defaultText="Need help making a withdrawal ? Our tutorials are here to help you."
            />
          </TutorialWrapper>
        </TitleText>
      </TitleWrapper>

      <ItemListContainer>
        <ItemListTitle>Withdrawal</ItemListTitle>

        <ItemListContentWrapper>
          {data?.WithdrawalCrypto !== "" && (
            <ItemListType>
              {data?.WithdrawalCrypto === "BEP-20" ? "BEP-20" : "TRC-20"}
            </ItemListType>
          )}

          <ItemListContent>{data?.Withdrawal}</ItemListContent>

          <ItemListVerification>
            <ItemListVerificationIcon verified={data?.IsWithdrawalVerified}>
              <Icons.Check />
            </ItemListVerificationIcon>
            <ItemListVerificationText>
              <Lang datakey="verified" defaultText="Verified" />
            </ItemListVerificationText>
          </ItemListVerification>

          <ItemListButtonWrapper>
            <ItemListButton
              onClick={() => {
                handleOpenModal("withdrawalVerify");
              }}
            >
              <Lang datakey="edit" defaultText="Edit" />
            </ItemListButton>
          </ItemListButtonWrapper>
        </ItemListContentWrapper>
      </ItemListContainer>

      {/* <TitleWrapper>
        <TitleIcon>
          <Icons.Key />
        </TitleIcon>
        <TitleText>
          <Lang datakey="setting_subTitle_api" defaultText="API Management" />
          <TutorialWrapper onClick={() => navigate(routes.ApiKeyGuide_v2)}>
            <Lang
              datakey="apikey_help_notice"
              defaultText="Need help creating an API key? Our tutorials are here to help you."
            />
          </TutorialWrapper>
        </TitleText>
      </TitleWrapper>

      <ItemListContainer>
        <ItemListTitle>Binance</ItemListTitle>
        <ItemListContentWrapper>
          <ItemListContent>{apiKey.Binance?.ApiKey}</ItemListContent>

          <ItemListVerification>
            <ItemListVerificationIcon
              verified={apiKey.Binance?.Abnormal?.toLowerCase() === "none"}
            >
              <Icons.Check />
            </ItemListVerificationIcon>
            <ItemListVerificationText>
              <Lang datakey="valid" defaultText="Valid" />
            </ItemListVerificationText>
          </ItemListVerification>

          <ItemListButtonWrapper>
            <ItemListButton
              onClick={() => {
                handleOpenModal("apikey", 1);
              }}
            >
              <Lang datakey="edit" defaultText="Edit" />
            </ItemListButton>
          </ItemListButtonWrapper>
        </ItemListContentWrapper>
      </ItemListContainer>

      <ItemListContainer>
        <ItemListTitle>Bybit</ItemListTitle>

        <ItemListContentWrapper>
          <ItemListContent>{apiKey.Bybit?.ApiKey}</ItemListContent>

          <ItemListVerification>
            <ItemListVerificationIcon
              verified={apiKey.Bybit?.Abnormal?.toLowerCase() === "none"}
            >
              <Icons.Check />
            </ItemListVerificationIcon>
            <ItemListVerificationText>
              <Lang datakey="valid" defaultText="Valid" />
            </ItemListVerificationText>
          </ItemListVerification>

          <ItemListButtonWrapper>
            <ItemListButton
              onClick={() => {
                handleOpenModal("apikey", 2);
              }}
            >
              <Lang datakey="edit" defaultText="Edit" />
            </ItemListButton>
          </ItemListButtonWrapper>
        </ItemListContentWrapper>
      </ItemListContainer>

      <ItemListContainer>
        <ItemListTitle>IG</ItemListTitle>

        <ItemListContentWrapper>
          <ItemListContent>{apiKey.Ig?.ApiKey}</ItemListContent>

          <ItemListVerification>
            <ItemListVerificationIcon
              verified={apiKey.Ig?.Abnormal?.toLowerCase() === "none"}
            >
              <Icons.Check />
            </ItemListVerificationIcon>
            <ItemListVerificationText>
              <Lang datakey="valid" defaultText="Valid" />
            </ItemListVerificationText>
          </ItemListVerification>

          <ItemListButtonWrapper>
            <ItemListButton
              onClick={() => {
                handleOpenModal("apikey", 4);
              }}
            >
              <Lang datakey="edit" defaultText="Edit" />
            </ItemListButton>
          </ItemListButtonWrapper>
        </ItemListContentWrapper>
      </ItemListContainer> */}

      <TitleWrapper>
        <TitleIcon>
          <Icons.Terms />
        </TitleIcon>
        <TitleText>
          <Element name="terms">
            <Lang datakey="terms_title" defaultText="Terms Of Service" />
          </Element>
        </TitleText>
        <ItemListButtonWrapper>
          <ItemListButton onClick={handleTermsOfService}>
            <Lang datakey="go" defaultText="Go" />
          </ItemListButton>
        </ItemListButtonWrapper>
      </TitleWrapper>

      {referralModalIsOpen && (
        <ReferralModal
          code={data?.ReferenceCode}
          setReferralModalIsOpen={setReferralModalIsOpen}
        />
      )}

      {open && (
        <SettingModal
          modalData={settingModalData}
          setClose={handleClose}
          submitFun={handleUpdateProfile}
          name={name}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          phone={phone}
          countryCode={countryCode}
          referanceCode={referanceCode}
          withdrawal={withdrawal}
          withdrawlVerifyCode={withdrawlVerifyCode}
          telegramId={telegramId}
          newApikey={newApikey}
          newSecretkey={newSecretkey}
          newIgAccount={newIgAccount}
          newIgPassword={newIgPassword}
          exchange={exchange}
        />
      )}
      {imgModalIsOpen && (
        <ApiKeyPermissionModal
          exchange={exchange}
          setImgModalIsOpen={setImgModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default Profile;
