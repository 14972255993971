import Cookies from "js-cookie";

const Host = process.env.REACT_APP_apiHost;
const Host_v2 = process.env.REACT_APP_apiv2Host;
// -- use cors-anywhere to fetch api data
// const corsURL = 'https://cors-anywhere.herokuapp.com/';
// const Host = corsURL + process.env.REACT_APP_apiHost;

// #region common

const jsonToUrlencoded = (data) => {
  const encodedData = new URLSearchParams();
  Array.from(Object.keys(data)).map((key) =>
    encodedData.append(key, data[key])
  );

  return encodedData;
};

const handleEncodedRequestOption = ({
  method = "GET",
  body = {},
  auth = false,
  contentType = "application/x-www-form-urlencoded",
}) => {
  const token = localStorage.getItem("token");
  const v2Token = Cookies.get("avaToken_v2");
  const systemToken = localStorage.getItem("systemToken");
  const headers = {
    "Content-Type": `${contentType}; charset=utf-8`,
    Accept: "*",
  };

  if (auth === "system") headers["Authorization"] = `Bearer ${systemToken}`;
  else if (auth === "v2") headers["Authorization"] = `Bearer ${v2Token}`;
  else if (auth) headers["Authorization"] = `Bearer ${token}`;

  const options = {
    redirect: "follow",
    method: method,
    headers,
  };

  if (method !== "GET")
    options["body"] = contentType.includes("json")
      ? body
      : jsonToUrlencoded(body);

  return options;
};

const tryFetch = async (path, requestOption) => {
  // console.log(`path: ${path}`);
  // console.log(`request: ${JSON.stringify(requestOption)}`);
  try {
    const response = await fetch(
      path,
      handleEncodedRequestOption(requestOption)
    );
    if (response.status === 401) {
      if (requestOption?.auth === "system")
        localStorage.removeItem("systemToken");
      else localStorage.removeItem("token");

      if (requestOption?.auth === "v2") window.location.href = "/v2/login";
      else window.location.reload();
    }
    return await response.json();
  } catch (error) {
    return {
      ResultCode: 999,
      Message: error,
    };
  }
};
// #endregion

// #region v1
// --- 00-template ---
// export const API_Template = async ({ mode }) => {
//   const path = `${Host}/api/AvaAPI/Template`;
//   const body = {
//     Mode: mode
//   }

//   if (!mode) {
//     console.error('Needs "mode" parameter.')
//     return;
//   }

//   // try fetch
//   const requestOption = { method: 'POST', body, auth: true };
//   return await tryFetch(path, requestOption);
// };

// --- 0.1-儲值AVAPoint ---
export const API_DepositAVAPoint = async ({
  mode = null,
  amount,
  TXID,
  ava_point_deposit_id,
}) => {
  const path = `${Host}/api/AvaAPI/DepositAVAPoint`;

  if (!mode) return console.error("Needs `mode` parameter.");

  if (!amount && !ava_point_deposit_id)
    return console.error("Needs `amount` or `ava_point_deposit_id` parameter.");

  const body = {
    Mode: mode,
  };

  if (!!ava_point_deposit_id) {
    if (!TXID) return console.error("Needs `TXID` parameter.");
    body["ava_point_deposit_id"] = ava_point_deposit_id;
    body["TXID"] = TXID;
  }

  if (!!amount) {
    body["amount"] = amount;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 01-GetToken --- V
export const API_GetToken = async ({ email = "", password = "" }) => {
  const path = `${Host}/token`;

  const body = {
    grant_type: "password",
    username: email,
    password,
  };

  // try fetch
  const requestOption = { method: "POST", body };
  return await tryFetch(path, requestOption);
};

// --- 03-註冊 ---
export const API_Register = async ({
  step = "1",
  phone,
  verifyCode = "",
  email,
  password,
}) => {
  const path = `${Host}/api/AvaAPI/Register`;

  if (!phone)
    return console.error("Needs `phone` parameter (start with country code)");
  const body = {
    Step: step,
    Phone: phone,
  };

  // --- 03.1-註冊_發送簡訊驗證 ---
  // step 1

  // --- 03.2-註冊_簡訊驗證碼驗證 ---
  if (step === "2") {
    if (!verifyCode)
      return console.error("step 2 Needs `verifyCode` parameter");

    body["VerifyCode"] = verifyCode;
  }

  // --- 03.3-註冊_確定註冊(發送Email驗證) ---
  if (step === "3") {
    if (!email) return console.error("step 3 Needs `email` parameter");
    if (!password) return console.error("step 3 Needs `password` parameter");

    body["Email"] = email;
    body["Password"] = password;
  }

  // try fetch
  const requestOption = { method: "POST", body };
  return await tryFetch(path, requestOption);
};

// --- 03.4-註冊_Email裡面的驗證連結(id為Email to Base64) ---
export const API_MailVerify = async ({ email }) => {
  const emailBase64 = btoa(email);
  const path = `${Host}/api/AvaAPI/MailVerify/?id=${emailBase64}`;

  // try fetch
  const requestOption = { method: "GET" };
  return await tryFetch(path, requestOption);
};

// --- 04-忘記密碼 ---
export const API_ForgetPassword = async ({ email, verifyCode, password }) => {
  const path = `${Host}/api/AvaAPI/ForgetPassword`;
  const body = {};

  // --- 04.1-忘記密碼_發送Email驗證 ---
  if (!email) {
    console.error('Needs "email" parameter.');
    return;
  }
  body["Email"] = email;

  // --- 04.2-忘記密碼_驗證碼驗證 ---
  if (!!verifyCode) body["VerifyCode"] = verifyCode;

  // --- 04.3-忘記密碼_更新密碼 ---
  if (!!password) body["NewPassword"] = password;

  // try fetch
  const requestOption = { method: "POST", body };
  return await tryFetch(path, requestOption);
};

// --- 05.1-取得帳號資訊 --- V
export const API_GetAccountData = async () => {
  const path = `${Host}/api/AvaAPI/GetAccountData`;

  // try fetch
  const requestOption = { auth: true };
  return await tryFetch(path, requestOption);
};

// --- 05.1.1-設定TelegramID ---
export const API_SetTelegramID = async ({ TelegramID }) => {
  const path = `${Host}/api/AvaAPI/SetTelegramID`;

  if (!TelegramID) return console.error('Needs "TelegramID" parameter.');
  const body = { TelegramID };

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 05.3-帳號_變更密碼 --- V
export const API_EditAccount = async ({
  name = "",
  oldPassword = "",
  newPassword = "",
}) => {
  const path = `${Host}/api/AvaAPI/EditAccount`;
  const body = {};

  if (!!name.toString()) body["Name"] = name.toString();

  if (!!oldPassword.toString() || !!newPassword.toString()) {
    if (!oldPassword.toString())
      return console.error('Needs "oldPassword" parameter.');
    if (!newPassword.toString())
      return console.error('Needs "newPassword" parameter.');

    body["OldPassword"] = oldPassword.toString();
    body["NewPassword"] = newPassword.toString();
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 08.2-取得推薦關係 --- V
export const API_GetAccountRelationship = async () => {
  const path = `${Host}/api/AvaAPI/GetAccountRelationship`;

  // try fetch
  const requestOption = { method: "GET", auth: true };
  return await tryFetch(path, requestOption);
};
// --- 09-AHFT ---
export const API_InvestAHFT = async ({
  mode,
  Amount = null,
  Asset = null,
  Address = null,
  ReturnType = null,
  PackageID = null,
  Name = null,
  groupId,
  TxID,
}) => {
  const path = `${Host}/api/AvaAPI/InvestAHFT`;
  const body = {
    Mode: mode,
  };

  // --- 09.1-AHFT_查詢
  // { mode: 'Query' }

  // --- 09.6-AHFT_近期紀錄(Dashboard長條圖) ---
  // { mode: 'PKGTypeChartStatic' }

  if (!mode) {
    console.error('Needs "mode" parameter.');
    return;
  }

  // --- 09.1.3-AHFT_查詢-投資項目詳細內容 ---
  if (mode === "GetInvestmentDetails") {
    if (!groupId) {
      console.error('"GetInvestmentDetails" Mode Needs "groupId" parameter.');
      return;
    }

    body["GroupID"] = groupId;
  }

  // --- 09.1.4-AHFT_-查詢-轉換投資預覽 ---
  if (mode === "PackageTransferPreview") {
    if (!groupId) {
      console.error('"GetInvestmentDetails" Mode Needs "groupId" parameter.');
      return;
    }
    if (!PackageID) {
      console.error('"GetInvestmentDetails" Mode Needs "PackageID" parameter.');
      return;
    }

    body["GroupID"] = groupId;
    body["PackageID"] = PackageID;
  }

  // --- 09.1.5-AHFT_-查詢-轉換投資執行 ---
  if (mode === "PackageTransferCommit") {
    if (!groupId) {
      console.error('"PackageTransferCommit" Mode Needs "groupId" parameter.');
      return;
    }
    if (!PackageID) {
      console.error(
        '"PackageTransferCommit" Mode Needs "PackageID" parameter.'
      );
      return;
    }
    if (!Name) {
      console.error('"PackageTransferCommit" Mode Needs "Name" parameter.');
      return;
    }

    body["GroupID"] = groupId;
    body["PackageID"] = PackageID;
    body["Name"] = Name;
    body["ReturnType"] = "transfer";
  }

  // --- 09.2-AHFT_新增 ---
  if (mode === "Insert") {
    // const params = { Amount, Asset, Address, ReturnType, PackageID, Name };
    const params = { Amount, Asset, ReturnType, PackageID, Name };
    const nullParams = Array.from(Object.keys(params)).filter(
      (key) => params[key] === null
    );
    if (nullParams.length !== 0)
      return console.error(
        `"Insert" Mode Needs "${nullParams.join(", ")}" parameters.`
      );

    Object.keys(params).map((key) => (body[key] = params[key]));
  }

  // --- 09.3-AHFT_回覆TxID ---
  if (mode === "ReplyTxid") {
    if (!groupId)
      return console.error('"ReplyTxid" Mode Needs "groupId" parameter.');
    if (!TxID) return console.error('"ReplyTxid" Mode Needs "TxID" parameter.');

    body["GroupID"] = groupId;
    body["TxID"] = TxID;
  }

  // --- 09.4.1-AHFT_編輯-更新返還方式 ---
  if (mode === "UpdateReturnType") {
    if (!groupId)
      return console.error(
        '"UpdateReturnType" Mode Needs "groupId" parameter.'
      );
    if (!ReturnType)
      return console.error(
        '"UpdateReturnType" Mode Needs "ReturnType" parameter.'
      );

    body["GroupID"] = groupId;
    body["ReturnType"] = ReturnType;
  }

  // --- 09.4.2-AHFT_編輯-更新名稱 ---
  if (mode === "UpdateName") {
    if (!groupId)
      return console.error('"UpdateName" Mode Needs "groupId" parameter.');
    if (!Name)
      return console.error('"UpdateName" Mode Needs "Name" parameter.');

    body["GroupID"] = groupId;
    body["Name"] = Name;
  }

  // --- 09.5-AHFT_刪除 ---
  if (mode === "Delete") {
    if (!groupId) {
      console.error('"Delete" Mode Needs "groupId" parameter.');
      return;
    }

    body["GroupID"] = groupId;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 10-AHFT後台_返還(後台用) ---
export const API_InvestAHFTBackend = async (updateGroup = null) => {
  const path = `${Host}/api/AvaAPI/InvestAHFTBackend`;
  const body = {
    Mode: "QueryReturn",
  };
  const requestOption = { method: "POST", body, auth: "system" };

  // --- 10.3-AHFT後台_取得返還群組(後台用) ---
  // updateGroup === null

  // --- 10.4-AHFT後台_確認已返還 ---
  if (updateGroup !== null) {
    let initValue = 0;
    const data = {
      Mode: "ReturnDone",
      TotalReturnAmount:
        Math.floor(
          updateGroup.reduce(
            (prev, { ReturnAmount }) => prev + ReturnAmount,
            initValue
          ) * 100000000
        ) / 100000000,
      ListReturn: updateGroup,
    };
    requestOption["body"] = JSON.stringify(data);
    requestOption["contentType"] = "application/json";
  }

  // try fetch
  return await tryFetch(path, requestOption);
};

// --- 11-交易所_查詢 ---
export const API_Exchange = async ({ mode, Exchange, ApiKey, SecretKey }) => {
  const path = `${Host}/api/AvaAPI/Exchange`;
  const body = {
    Mode: mode,
  };

  if (!mode) {
    console.error('Needs "mode" parameter.');
    return;
  }
  // --- 11.1-交易所_查詢 ---
  // { mode: 'Query' }

  // --- 11.2-編輯ApiKey ---
  if (mode === "Connect") {
    if (!Exchange) {
      console.error('"Connect" Mode Needs "Exchange" parameter.');
      return;
    }

    if (!ApiKey) {
      console.error('"Connect" Mode Needs "ApiKey" parameter.');
      return;
    }

    if (!SecretKey) {
      console.error('"Connect" Mode Needs "SecretKey" parameter.');
      return;
    }

    body["Exchange"] = Exchange;
    body["ApiKey"] = ApiKey;
    body["SecretKey"] = SecretKey;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 11.2-AIRobotList ---
export const API_AIRobotList = async () => {
  const path = `${Host}/api/AvaAPI/AIRobotList`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

// --- 11.3-交易所_刪除AI ---
export const API_DeleteAIEntrust = async ({
  entrust_id = null,
  Exchange = null,
  ai_robot_id = null,
  account_id = null,
}) => {
  const path = `${Host}/api/AvaAPI/DeleteAIEntrust`;
  const body = {
    entrust_id,
  };

  if (!entrust_id) {
    console.error('Needs "entrust_id" parameter.');
    return;
  }
  // if (!Exchange) {
  //   console.error('Needs "Exchange" parameter.')
  //   return;
  // }
  // if (!ai_robot_id) {
  //   console.error('Needs "ai_robot_id" parameter.')
  //   return;
  // }
  // if (!account_id) {
  //   console.error('Needs "account_id" parameter.')
  //   return;
  // }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 11.11-交易所_AI委託 ---
export const API_SetAIEntrust = async ({
  Exchange = null,
  ai_robot_id = null,
  amount = null,
}) => {
  const path = `${Host}/api/AvaAPI/SetAIEntrust`;
  const body = {
    Exchange,
    ai_robot_id,
    amount,
  };

  if (!Exchange) {
    console.error('Needs "Exchange" parameter.');
    return;
  }
  if (!ai_robot_id) {
    console.error('Needs "ai_robot_id" parameter.');
    return;
  }
  if (!amount) {
    console.error('Needs "amount" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 14-交易紀錄頁 --- V
export const API_UiTransactionRecord = async ({ mode, monthAgo = 3 }) => {
  const path = `${Host}/api/AvaAPI/UiTransactionRecord`;
  const body = {
    Mode: mode,
  };

  // --- 14.1-交易紀錄頁_所有交易紀錄 ---
  if (!mode) {
    console.error('Needs "mode" parameter.');
    return;
  }

  // --- 14.4-交易紀錄頁_投資獎勵 ---
  if (!!mode && mode === "InvestReward") body["monthAgo"] = monthAgo;

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 15.3-取得公布資訊-POST --- V
export const API_GetAnnouncement = async () => {
  const path = `${Host}/api/AvaAPI/GetAnnouncement`;

  // try fetch
  const requestOption = { method: "POST", auth: true };
  return await tryFetch(path, requestOption);
};

// --- 17.2-系統資訊 - 取得即時匯率 ---
export const API_GetCurrencyRate = async () => {
  const path = `${Host}/api/AvaAPI/GetCurrencyRate`;

  // try fetch
  const requestOption = { method: "GET", auth: true };
  return await tryFetch(path, requestOption);
};

// --- 17.4 系統資訊 - 寄送帳戶報表 ---
export const API_SendAccountReport = async () => {
  const path = `${Host}/api/AvaAPI/sendAccountReport`;

  // try fetch
  const requestOption = { method: "POST", auth: true };
  return await tryFetch(path, requestOption);
};

// --- 17.A-系統資訊 - 取得即時匯率 (from binance) ---
// reference: https://binance-docs.github.io/apidocs/spot/en/#24hr-ticker-price-change-statistics
export const API_GetCurrencyRate_binance = async (symbol = null) => {
  const assets = [
    "BTC",
    "ETH",
    "IOTA",
    "BCH",
    "NEO",
    "EOS",
    "ETC",
    "BNB",
    "CAKE",
  ];
  const target =
    symbol === null
      ? assets.map((asset) => `${asset}USDT`).join('","')
      : `${symbol}USDT`;
  const path = `https://api.binance.com/api/v3/ticker/price?symbols=%5B"${target}"%5D`;

  // try fetch
  const requestOption = { method: "GET" };
  return await tryFetch(path, requestOption);
};

// --- 17.B-系統資訊 - 取得過去一年匯率 (from binance) ---
// reference: https://binance-docs.github.io/apidocs/spot/en/#compressed-aggregate-trades-list (search: kline)
// [
//   [
//     1499040000000,      // Open time  [0]
//     "0.01634790",       // Open
//     "0.80000000",       // High
//     "0.01575800",       // Low
//     "0.01577100",       // Close      [4]
//     "148976.11427815",  // Volume
//     1499644799999,      // Close time [6]
//     "2434.19055334",    // Quote asset volume
//     308,                // Number of trades
//     "1756.87402397",    // Taker buy base asset volume
//     "28.46694368",      // Taker buy quote asset volume
//     "17928899.62484339" // Ignore.
//   ]
// ]
export const API_GetYearRate_binance = async () => {
  const assets = [
    "BTC",
    "ETH",
    "IOTA",
    "BCH",
    "NEO",
    "EOS",
    "ETC",
    "BNB",
    "CAKE",
  ];
  const requestOption = { method: "GET" };

  const rates = [];

  assets.map(async (asset) => {
    const path = `https://api.binance.com/api/v3/klines?symbol=${asset}USDT&interval=1d&limit=365`;
    return fetch(path, requestOption)
      .then((response) => response.json())
      .then((result) =>
        rates.push({
          asset,
          info: result.map((data) => ({
            openTime: data[0],
            closeTime: data[6],
            closeValue: data[4],
          })),
        })
      )
      .catch((error) => console.error("error", error));
  });

  return rates;
};

// --- 17.C-系統資訊 - 目前目標的平均價格 (from binance) ---
// reference: https://binance-docs.github.io/apidocs/spot/en/#current-average-price
// {
//   "mins": 5,
//   "price": "9.35751834"
// }
export const API_AvgPrice_binance = async (targets) => {
  const requestOption = { method: "GET" };
  const promise = [];
  const prices = {};
  targets.map(async (target) => {
    const path = `https://api.binance.com/api/v3/avgPrice?symbol=${target}USDT`;
    promise.push(
      fetch(path, requestOption)
        .then((response) => response.json())
        .then((result) => (prices[target] = +result.price))
        .catch((error) => console.error("error", error))
    );
  });

  return Promise.all(promise).then(() => prices);
};

// --- 17.D-系統資訊 - 取得指定匯率 (from binance) ---
// reference: https://binance-docs.github.io/apidocs/spot/en/#24hr-ticker-price-change-statistics
export const API_GetCustomRate_binance = async ({
  symbol = null,
  interval = "4h",
  startTime = null,
  endTime = null,
}) => {
  if (!symbol) return console.error("Needs `symbol` parameter.");
  if (!startTime) return console.error("Needs `startTime` parameter.");
  if (!endTime) return console.error("Needs `endTime` parameter.");

  const targets = [symbol, "BTCUSDT", "ETHUSDT"];
  const requestOption = { method: "GET" };
  const promise = [];

  const info = {};
  info[symbol] = [];
  info["BTCUSDT"] = [];
  info["ETHUSDT"] = [];

  targets.map(async (target) => {
    const path = `https://api.binance.com/api/v3/klines?symbol=${target}&interval=${interval}&startTime=${startTime}&endTime=${endTime}`;
    promise.push(
      fetch(path, requestOption)
        .then((response) => response.json())
        .then((result) => {
          let sma = 0;
          let atr = 0;
          let dateIndex = 0;
          let prevClose = 0;
          result.map((data, index) => {
            const hour = new Date(data[0]).getHours();

            const tr =
              prevClose === 0
                ? +data[2] - +data[3]
                : Math.max(
                    +data[2] - +data[3], // high - low
                    Math.abs(+data[2] - prevClose), // ABS(high - prev close)
                    Math.abs(+data[3] - prevClose) // ABS(low - prev close)
                  );

            if (hour === 0) {
              if (index !== dateIndex) {
                info[target][dateIndex]["sma"] = sma / 6;
                info[target][dateIndex]["atr"] = atr / 6;
              }

              dateIndex = index;
              sma = +data[4];
              atr = tr;
            } else {
              sma += +data[4]; // close
              atr += tr;
            }

            prevClose = +data[4];

            return info[target].push({
              time: data[0],
              open: data[1],
              high: data[2],
              low: data[3],
              close: data[4],
              volume: data[5],
              sma: 0,
              atr: 0,
            });
          });
        })
        .catch((error) => console.error("error", error))
    );
  });

  return Promise.all(promise).then(() => info);
};

// --- 19-取得 Q&A --- V
export const API_GetQnA = async () => {
  const path = `${Host}/api/AvaAPI/GetQnA`;

  // try fetch
  const requestOption = { method: "GET", auth: true };
  return await tryFetch(path, requestOption);
};

// --- 20-AVA Point 提領 ---
export const API_AvaPointWithdrawal = async ({ mode, UserAddress, Point }) => {
  const path = `${Host}/api/AvaAPI/AvaPointWithdrawal`;
  const body = {
    Mode: mode,
  };

  if (!mode) {
    console.error('Needs "mode" parameter.');
    return;
  }

  // --- 20.1-AVA Point 提領申請 ---
  if (mode === "Apply") {
    if (!Point) return console.log("`Apply` mode needs `Point` parameter.");

    body["Point"] = Point;
  }

  // --- 20.3-更換User Address ---
  if (mode === "UpdateAddress") {
    if (!UserAddress) {
      console.error('"UpdateAddress" Mode Needs "UserAddress" parameter.');
      return;
    }

    body["UserAddress"] = UserAddress;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 21-後台AVA Point 提領 ---
export const API_AvaPointWithdrawalBackend = async (id = null) => {
  const path = `${Host}/api/AvaAPI/AvaPointWithdrawalBackend`;
  const body = {
    Mode: "QueryByStatus",
    Status: "Review",
  };

  // --- 21.1-後台查詢AVA Point 提領紀錄(QueryByStatus) ---
  // id === null

  // --- 21.3-後台AVA Point 提領審核 ---
  if (id !== null) {
    body["ID"] = id;
    body["Mode"] = "Review";
    body["Status"] = "Done";
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: "system" };
  return await tryFetch(path, requestOption);
};

// --- 23.2-InfoBotList ---
export const API_InfoBotList = async () => {
  const path = `${Host}/api/AvaAPI/InfoBotList`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

// --- 23.3-InfoBotListById ---
export const API_InfoBotListById = async () => {
  const path = `${Host}/api/AvaAPI/InfoBotListById`;

  // try fetch
  const requestOption = { method: "POST", auth: true };
  return await tryFetch(path, requestOption);
};

// --- 23.4-SubscribeInfoBot ---
export const API_SubscribeInfoBot = async ({
  InfoBotID = -1,
  isSubscribe = null,
}) => {
  const path = `${Host}/api/AvaAPI/SubscribeInfoBot`;

  if (InfoBotID === -1) {
    return console.error('Needs "InfoBotID" parameter.');
  }

  if (isSubscribe === null) {
    return console.error('Needs "isSubscribe" parameter.');
  }

  const body = {
    InfoBotID,
    isSubscribe,
  };

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 24-MinningSystemStaticData ---
export const API_MiningSystemStaticData = async () => {
  const path = `${Host}/api/AvaAPI/MinningSystemStaticData`;

  // try fetch
  const requestOption = { method: "GET" };
  return await tryFetch(path, requestOption);
};

// --- 25-AlgoTradeStaticData ---
export const API_AlgoTradeStaticData = async () => {
  const path = `${Host}/api/AvaAPI/AlgoTradeStaticData`;

  // try fetch
  const requestOption = { method: "GET" };
  return await tryFetch(path, requestOption);
};

// --- TrendFollowingBTResult ---
export const API_TrendFollowingBTResult = async () => {
  const path = `${Host}/api/AvaAPI/TrendFollowingBTResult`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

// --- SetReferrer ---
export const API_SetReferrer = async ({ ReferenceCode }) => {
  const path = `${Host}/api/AvaAPI/SetReferrer`;
  const body = { ReferenceCode };

  // try fetch
  const requestOption = { method: "POST", body, auth: true };
  return await tryFetch(path, requestOption);
};

// --- 99.1-取得 Transfer QRcode ---
// https://api.cryptapi.io/${transferID.toLowerCase()}/usdt/qrcode/?address=${ADDRESS[transferID]}
// export const API_GetTransferQrcode = async (transferType, address) => {
//   const path = `https://api.cryptapi.io/${transferType.toLowerCase()}/usdt/qrcode/?address=${address}`;

//   // try fetch
//   const requestOption = { method: 'GET', auth: false };
//   return await tryFetch(path, requestOption);
// };
// #endregion

// #region v2
// --- 00-template ---
// export const API_Template = async ({ mode }) => {
//   const path = `${Host_v2}/api/AvaAPI/Template`;
//   const body = {
//     Mode: mode
//   }

//   if (!mode) {
//     console.error('Needs "mode" parameter.')
//     return;
//   }

//   // try fetch
//   const requestOption = { method: 'POST', body, auth: true };
//   return await tryFetch(path, requestOption);
// };

// --- Register SendMail ---

export const API_Login = async ({ Account, Password }) => {
  const path = `${Host_v2}/auth/Login`;
  const body = { Account, Password };

  if (!Account) {
    console.error('Needs "Account" parameter.');
    return;
  }

  if (!Password) {
    console.error('Needs "Password" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_SendMail = async ({ Account }) => {
  const path = `${Host_v2}/api/account/SendMail`;
  const body = { Account };

  if (!Account) {
    console.error('Needs "Account" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetVerifyAccount = async ({ VerifyCode }) => {
  const path = `${Host_v2}/auth/GetVerifyAccount`;
  const body = { VerifyCode };

  if (!VerifyCode) {
    console.error('Needs "VerifyCode" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_VerifyEmail = async ({ Account, VerifyCode }) => {
  const path = `${Host_v2}/api/account/VerifyEmail`;
  const body = { Account, VerifyCode };

  if (!Account) {
    console.error('Needs "Account" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_SetPassword = async ({ Account, Password }) => {
  const path = `${Host_v2}/api/account/SetPassword`;
  const body = { Account, Password };

  if (!Account) {
    console.error('Needs "Account" parameter.');
    return;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_AccountProfile = async () => {
  const path = `${Host_v2}/api/account/AccountProfile`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

export const API_SetAccountProfile = async ({
  Account,
  Name,
  OldPassword,
  NewPassword,
}) => {
  const path = `${Host_v2}/api/account/AccountProfile`;
  const body = { Account };

  if (!!Name) body["Name"] = Name;

  if (!!NewPassword) {
    body["NewPassword"] = NewPassword;
    body["OldPassword"] = OldPassword;
  }

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetAhftReturnGroup = async () => {
  const path = `${Host_v2}/mining/GetAhftReturnGroup`;

  // try fetch
  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetMiningApplyTransferGroup = async () => {
  const path = `${Host_v2}/backend/GetMiningApplyTransferGroup`;

  // try fetch
  const requestOption = { method: "GET" };
  return await tryFetch(path, requestOption);
};

export const API_CommitMiningApplyTransferGroup = async ({ AhftGroupIds }) => {
  const path = `${Host_v2}/backend/CommitMiningApplyTransferGroup`;
  const body = { AhftGroupIds };

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetRobots = async () => {
  const path = `${Host_v2}/backend/GetRobots`;

  // try fetch
  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetRobotTargetDetail = async (body) => {
  const path = `${Host_v2}/backend/GetRobotTargetDetail`;

  // try fetch
  const requestOption = { method: "POST", auth: "v2", body };
  return await tryFetch(path, requestOption);
};

export const API_SetEntrustMaxLaverageGroupByRobotId = async (body) => {
  const path = `${Host_v2}/backend/SetEntrustMaxLaverageGroupByRobotId`;

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetAlgoTargets = async () => {
  const path = `${Host_v2}/backend/GetEntrustTargets`;

  // try fetch
  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_UpdateAlgoTargets = async (formdata) => {
  var headers = {
    Authorization: `Bearer ${Cookies.get("avaToken_v2")}`,
  };

  const requestOption = {
    method: "POST",
    body: formdata,
    headers,
  };

  return fetch(`${Host_v2}/backend/UpdateEntrustTarget`, requestOption)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => JSON.parse(error));
};

export const API_UpdateRobotTargetDetail = async (body) => {
  const path = `${Host_v2}/backend/UpdateRobotTargetDetail`;

  // try fetch
  const requestOption = { method: "POST", body, auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_CreateOrders = async (body) => {
  const path = `${Host_v2}/algo/CreateOrders`;

  // try fetch
  const requestOption = {
    method: "POST",
    body,
    auth: false,
    contentType: "application/json",
  };
  return await tryFetch(path, requestOption);
};

export const API_GetCurrentPrice = async () => {
  const path =
    "https://api.bybit.com/v5/market/tickers?category=linear&symbol=BTCUSDT";

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetAccountsVerifies = async () => {
  const path = `${Host_v2}/backend/GetAccountsVerifies`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetAccountVerifyInfo = async (body) => {
  const path = `${Host_v2}/backend/GetAccountVerifyInfo`;

  // try fetch
  const requestOption = { method: "POST", body, auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetErrorApiLogs = async () => {
  const path = `${Host_v2}/backend/GetErrorApiLogs`;

  // try fetch
  const requestOption = { method: "GET", auth: false };
  return await tryFetch(path, requestOption);
};

export const API_GetRobotDataList = async () => {
  const path = `${Host_v2}/backend/GetGridRobotDataList`;

  // try fetch
  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetRobotDetail = async (id) => {
  const path = `${Host_v2}/backend/GetGridRobotDetails?robotId=${id}`;

  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_EditGridRobotParams = async (body) => {
  const path = `${Host_v2}/backend/EditGridRobotParams`;

  const requestOption = {
    method: "POST",
    body: body,
    auth: "v2",
    contentType: "application/json",
  };
  return await tryFetch(path, requestOption);
};

export const API_IsActiveGridRobot = async (body) => {
  const path = `${Host_v2}/backend/IsActiveGridRobot`;

  const requestOption = {
    method: "PUT",
    body: body,
    auth: "v2",
    contentType: "application/json",
  };
  return await tryFetch(path, requestOption);
};

export const API_StopGridRobot = async (body) => {
  const path = `${Host_v2}/Backend/KillGridRobotProcess`;

  const requestOption = {
    method: "PUT",
    body: body,
    auth: "v2",
    contentType: "application/json",
  };
  return await tryFetch(path, requestOption);
};

export const API_GetDownLineMarkDownAccount = async () => {
  const path = `${Host_v2}/Member/GetDownLineMemberMarkDown`;

  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetDownlineTransactionRecord = async () => {
  const path = `${Host_v2}/Member/GetDownlineTransactionRecord`;

  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetHiveParallelMarkDown = async (level) => {
  const path = `${Host_v2}/Member/GetHiveParallelMarkDown?level=${level}`;

  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

export const API_GetTotalHiveBenefit = async () => {
  const path = `${Host_v2}/Member/GetTotalHiveBenefit`;

  const requestOption = { method: "GET", auth: "v2" };
  return await tryFetch(path, requestOption);
};

// 下載 PersonalData
export const API_DownloadPersonalData = async () => {
  const path = `${Host_v2}/datacenter/DownloadPersonalData`;
  const requestOption = {
    method: "GET",
    auth: "v2",
  };

  const res = await fetch(path, handleEncodedRequestOption(requestOption));
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "personal_data.xls";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// 驗證 KYC
export const API_VerifyKyc = async (body) => {
  const path = `${Host_v2}/backend/VerifyKYC`;

  // try fetch
  const requestOption = { method: "POST", body, auth: "v2" };
  return await tryFetch(path, requestOption);
};
// #endregion
