import styled, { css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../constants/rwd";
import { CiMicrochip } from "react-icons/ci";
import Robot from "../../../images/robot.webp";
import Intro_1 from "../../../images/intro_1.svg";
import Intro_2 from "../../../images/intro_2.svg";
import Intro_3 from "../../../images/intro_3.svg";
import parse from "html-react-parser";
import { getLanguage } from "../../../v2/services/common";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 7.8125vw 0;
`;

const Container = styled.div`
  display: flex;
  width: 81.88vw;
`;

const TitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
`;

const Title = styled.h2`
  margin: 0;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const ProductWrapper = styled.div`
  display: flex;
  gap: 30px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${RWD_MD(css`
    gap: 20px;
  `)};
`;

const ProductCard = styled.div`
  color: #fcfcfc;
  display: flex;
  gap: 20px;
`;

const ProductTitle = styled.div`
  font-size: clamp(24px, 1.45833vw, 28px);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  ${RWD_MD(css`
    font-size: clamp(16px, 3.125vw, 24px);
  `)};
`;

const ProductDescription = styled.div`
  color: ${(props) => props.theme.colors.Grey};
  font-size: clamp(16px, 1.041667vw, 20px);

  ${RWD_MD(css`
    font-size: clamp(14px, 2.864vw, 22px);
  `)};
`;

const ProductIcon = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.colors.Green};
  /* background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%); */
  /* padding: 5px; */
  font-size: 35px;
  border-radius: 5px;
`;

const RobotImage = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Product = () => {
  const titleData = [
    {
      ch: "產品介紹",
      en: "Products",
    },
  ];
  const productList = [
    {
      id: 1,
      title: [
        {
          ch: "AI_Stacking",
          en: "AI_Stacking",
        },
      ],
      content: [
        {
          ch: "避險資產：<br>最低風險，最穩定的回報。",
          en: "Safe haven assets:<br>The lowest risk, most stable returns.",
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "AI_Increment",
          en: "AI_Increment",
        },
      ],
      content: [
        {
          ch: "長期持有的最佳選擇：<br>穩定增長，分享更廣泛市場的收益。",
          en: "The best choice for long-term holding:<br>Steady growth, sharing in the broader market's gains",
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "AI_Algo",
          en: "AI_Algo",
        },
      ],
      content: [
        {
          ch: "追求潛在市場：整合AI來選擇目標，使用算法計算買賣點。風險越高，回報越高。",
          en: "Pursuing potential markets:<br>Integrating AI to select targets, with Algo calculating buy and sell points. Higher risk, higher rewards.",
        },
      ],
    },
  ];

  const templateHandler = (data) => {
    if (getLanguage()?.toLocaleLowerCase() === "ch") {
      return parse(data.ch);
    } else {
      return parse(data.en);
    }
  };

  return (
    <Wrapper id="Products">
      <TitleWrapper>
        <Title data-aos="fade-up" data-aos-duration="1000">
          {titleData[0][getLanguage()?.toLocaleLowerCase()]}
        </Title>
      </TitleWrapper>

      <Container>
        <ProductWrapper>
          <RobotImage data-aos="zoom-in" data-aos-duration="1000">
            <img src={Robot} alt="robot" />
          </RobotImage>
          <ProductContainer>
            {productList.map((data) => (
              <ProductCard
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="linear"
                data-aos-duration="1000"
                key={data.id}
              >
                <ProductIcon>
                  <CiMicrochip />
                </ProductIcon>

                <ProductDescription>
                  <ProductTitle>
                    {data.title[0][getLanguage()?.toLocaleLowerCase()]}
                  </ProductTitle>
                  {templateHandler(data.content[0])}
                </ProductDescription>
              </ProductCard>
            ))}
          </ProductContainer>
        </ProductWrapper>
      </Container>
    </Wrapper>
  );
};

export default Product;
