import { useEffect, useState } from "react";
import {
  API_GetAccountVerifyInfo,
  API_VerifyKyc,
  API_VerifyKYC,
} from "../../../utils/api";
import styled from "styled-components";
import { Button } from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2";

const InfoItem = styled.div`
  padding: 0.3rem 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;

  > *:first-child {
    width: 8rem;
    color: #888;
  }
  > *:last-child {
    flex: 1;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
  textarea {
    padding: 1rem;
    border: 1px solid #ccc;
    min-height: 5rem;
  }
`;

const InfoAction = styled.div`
  padding-top: 1rem;
  > *:last-child {
    margin-left: 1rem;
  }
`;

const IMAGE_URL = "https://storage.googleapis.com/ava_kyc";

const KycInfo = ({ AccountId, handleGetAccountsVerifies }) => {
  const handleGetInfo = async () => {
    const body = {
      AccountId,
      Type: "Kyc",
    };
    const res = await API_GetAccountVerifyInfo(body);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res?.Info);
  };

  const getKycStatus = () => {
    if (!data) return;
    switch (data.IsVerified) {
      case null:
        return "未申請";
      case 0:
        return "處理中";
      case 1:
        return "驗證通過";
      default:
        return "";
    }
  };

  const getKycType = () => {
    if (!data) return;
    switch (data.Type) {
      case "1":
        return "身分證";
      case "2":
        return "護照";
      default:
        return "";
    }
  };

  const getKycCountry = () => {
    if (!data) return;
    switch (data.Country) {
      case "1":
        return "香港";
      case "2":
        return "台灣";
      default:
        return "";
    }
  };

  const handleFetchImageError = (e) => {
    if (!data?.KycId) return;
    const ext = e.target.src.split(".").at(-1);
    switch (ext) {
      case "jpg":
        console.log("try media type; jpeg");
        e.target.src = e.target.src.replace(".jpg", ".jpeg");
        return;

      case "jpeg":
        console.log("try media type: format");
        e.target.src = e.target.src.replace(".jpeg", ".png");
        return;

      case "png":
        console.error("cannot find image");
        return null;

      default:
        console.error("not supported media type");
        return null;
    }
  };

  const handleVerify = async (isPass) => {
    const body = {
      KycId: data.KycId,
      IsVerified: isPass,
    };
    if (!isPass) body.ErrorDescription = abortMsg;

    const res = await API_VerifyKyc(body);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    Swal.fire("Success", "已更新驗證狀態", "success");
    handleGetAccountsVerifies();
  };

  const [data, setData] = useState();
  const [abortMsg, setAbortMsg] = useState("");

  useEffect(() => {
    handleGetInfo();
  }, [AccountId]);

  return (
    <div>
      {/* 帳號(信箱) */}
      <InfoItem>
        <div>帳號(信箱)</div>
        <div>{AccountId}</div>
      </InfoItem>
      {/* 目前驗證狀態 */}
      <InfoItem>
        <div>目前驗證狀態</div>
        <div>{getKycStatus()}</div>
      </InfoItem>
      {/* KYC ID */}
      <InfoItem>
        <div>KYC ID</div>
        <div>{data?.KycId}</div>
      </InfoItem>
      {/* KYC 類別 */}
      <InfoItem>
        <div>KYC 類別</div>
        <div>{getKycType()}</div>
      </InfoItem>
      {/* 國家 */}
      <InfoItem>
        <div>國家</div>
        <div>{getKycCountry()}</div>
      </InfoItem>
      {/* 證件號碼 */}
      <InfoItem>
        <div>證件號碼</div>
        <div>{data?.Id}</div>
      </InfoItem>
      {/* 姓 */}
      <InfoItem>
        <div>姓</div>
        <div>{data?.FirstName}</div>
      </InfoItem>
      {/* 中間名 */}
      <InfoItem>
        <div>中間名</div>
        <div>{data?.MiddleName}</div>
      </InfoItem>
      {/* 名 */}
      <InfoItem>
        <div>名</div>
        <div>{data?.LastName}</div>
      </InfoItem>
      {/* 生日 */}
      <InfoItem>
        <div>生日</div>
        <div>{data?.Birthday}</div>
      </InfoItem>
      {/* 使用者上傳的圖片 */}
      {!!data?.KycId && (
        <>
          {+data?.Type === 1 && (
            <>
              <InfoItem>
                <div>身分證正面</div>
                <div>
                  <img
                    src={`${IMAGE_URL}/${data.KycId}/IdFront.jpg`}
                    onError={handleFetchImageError}
                  />
                </div>
              </InfoItem>
              <InfoItem>
                <div>身分證背面</div>
                <div>
                  <img
                    src={`${IMAGE_URL}/${data.KycId}/IdBack.jpg`}
                    onError={handleFetchImageError}
                  />
                </div>
              </InfoItem>
            </>
          )}
          {+data?.Type === 2 && (
            <>
              <InfoItem>
                <div>護照</div>
                <div>
                  <img
                    src={`${IMAGE_URL}/${data.KycId}/Passport.jpg`}
                    onError={handleFetchImageError}
                  />
                </div>
              </InfoItem>
            </>
          )}
          <InfoItem>
            <div>手持證件照</div>
            <div>
              <img
                src={`${IMAGE_URL}/${data.KycId}/Selfie.jpg`}
                onError={handleFetchImageError}
              />
            </div>
          </InfoItem>
        </>
      )}
      {/* 未通過原因 */}
      <InfoItem>
        <div>未通過原因</div>
        <textarea
          value={abortMsg}
          onChange={(e) => setAbortMsg(e.target.value)}
        />
      </InfoItem>
      {/* 操作 */}
      <InfoAction>
        <Button
          variant="contained"
          onClick={() => handleVerify(true)}
          disabled={data?.IsVerified !== 0}
        >
          通過
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleVerify(false)}
          disabled={data?.IsVerified === null || !abortMsg}
        >
          未通過
        </Button>
      </InfoAction>
    </div>
  );
};

export default KycInfo;
