import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { routes } from "../../../components/Router";
import { verifications } from "../../../utils/verifications";

// --- styled
import styled from "styled-components";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { css, keyframes } from "styled-components";

// --- plugins
import { TextField } from "@mui/material";

// --- api
import { API_GetVerifyAccount, API_SetPassword } from "../../../utils/api";
import Loading from "../../components/Loading/Loading";
import { getAuthToken } from "../../services/common";
import {
  useSendEmailMutation,
  useSetPasswordMutation,
  useVerifyEmailMutation,
  useVerifyTelegramMutation,
} from "../../services/authApi";
import { QRCodeCanvas } from "qrcode.react";
import { SuccessMySwal } from "../../global/MySwal";

import {
  PasswordCheck,
  TaiwanPhoneFormatCheck,
  HongKongPhoneFormatCheck,
} from "../../utils";
import { EmailFormat } from "../../utils";
import { Fragment } from "react";
import { changeLanguage, selectSystem } from "../../slices/systemSlice";
import { useSelector } from "react-redux";
import Lang from "../../global/Lang";
import Icons from "../../global/icons";
import { useDispatch } from "react-redux";
import { handleParseLanguage } from "../../utils";
import LogoImg from "../../../img/ava_logo_dark.png";
import Robot from "../../images/AVArobot.svg";
import parse from "html-react-parser";

// #region styled

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: none;
  position: relative;

  //
  background-color: #151944;
  color: #fcfcfc;
  font-family: Poppins;
`;

const RobotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 3vw;
  right: 3vw;

  ${RWD_LG(css`
    bottom: 5%;
    right: 8%;
  `)};

  ${RWD_MD(css`
    flex-direction: row;
    align-items: center;
  `)};
`;

const float = keyframes`
 
    0% {
        transform: translate3d(0, 0%, 0);
    }
    25% {
        transform: translate3d(0, 2%, 0);
    }
    75% {
        transform: translate3d(0, -2%, 0);
    }
    100% {
        transform: translate3d(0, 0%, 0);
    }

  
`;

const RobotImg = styled.div`
  display: inline-flex;
  width: clamp(150px, 13.020833vw, 250px);

  animation: ${float} 4s linear infinite;

  img {
    transform: scaleX(-1);
  }

  ${RWD_MD(css`
    width: 100px;
  `)};
`;

const ChatBubble = styled.div`
  position: relative;

  background: #fcfcfc;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  right: 10%;
  height: 150px;
  z-index: 2;

  /* top: calc(-100% - 24px); */
  /* right: 10%; */

  ${RWD_MD(css`
    right: 7%;
    border-radius: 10px;
    margin-bottom: 0;
    height: 100%;
    min-height: 120px;
  `)};

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 24px solid #fff;
    border-top: 12px solid #fff;
    border-bottom: 24px solid transparent;
    right: 55%;
    bottom: -20px;
    z-index: 1;
    ${RWD_LG(css`
      right: 40%;
    `)};

    ${RWD_MD(css`
      right: -5%;
      top: 50%;

      border-left: 12px solid #fff;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      border-bottom: 12px solid transparent;
    `)};
  }
`;

const ChatContent = styled.div`
  color: black;
  text-align: center;
  padding: 20px;
  width: 20vw;
  font-size: clamp(13px, 0.833vw, 16px);
  z-index: 2;
  ${RWD_LG(css`
    width: 45vw;
  `)};

  ${RWD_SM(css`
    width: 54vw;
    padding: 15px 10px;
  `)};
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 10px;
  width: clamp(600px, 40vw, 750px);
  padding: 2vw 2.5vw;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  ${RWD_LG(css`
    width: clamp(550px, 60vw, 620px);
    padding: 4vw;
    top: 45%;
  `)};

  ${RWD_MD(css`
    width: 70vw;
  `)};

  ${RWD_SM(css`
    width: 90vw;
    padding: 15px;
  `)};
`;

const Header = styled.header`
  height: 6.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${RWD_MD(css`
    height: 8vh;
  `)};
  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 81.88vw;
  ${RWD_MD(css`
    width: 90vw;
  `)};
`;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;
  cursor: pointer;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: end;
`;

const LanguageText = styled.div`
  cursor: pointer;
`;

const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;

  //
  padding-bottom: 20px;
`;

const RegisterInput = styled.input`
  color: #fcfcfc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 10px;
  margin: 10px 0;
  width: 100%;
  font-size: clamp(13px, 0.833vw, 16px);
`;

const TelegramInput = styled.input`
  color: #fcfcfc;

  padding: 10px 10px;
  margin: 10px 0;
  width: 100%;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Panel = styled.form`
  position: relative;
`;

const Field = styled.label`
  //
  width: 100%;
  padding: 8px 0;
`;

const TelegramField = styled.label`
  width: 100%;
`;

const FieldWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const QrcodeBtn = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Qrcode = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
`;

const ActionContainer = styled.div`
  padding: 30px 0 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Back = styled.div`
  position: absolute;
  padding: 10px;
  left: 10px;
  top: 10px;
  transition: 500ms;
  font-size: 16px;
  font-weight: bold;
  border-radius: 100%;
  cursor: pointer;

  :hover {
    color: #0aecd1;
    background: transparent;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button:last-child {
    margin-left: auto;
  }
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  button:last-child {
    align-self: flex-end;
  }
`;

const ButtonText = styled.span`
  padding: 8px 16px;
  text-align: center;
  font-size: min(max(1.0416vw, 14px), 20px);
  color: #fcfcfc;
  ${RWD_SM(css`
    min-width: 25vw;
    padding: 8px 10px;
  `)};
`;

const ErrorMsg = styled.div`
  font-size: clamp(14px, 0.833vw, 15px);
  color: #f05d2f;
  height: 20px;
`;

const Notice = styled.div`
  font-size: clamp(12.5px, 0.78125vw, 15px);
`;

const InviteWrapper = styled.div`
  padding-top: 20px;
`;

const CheckTermsTitle = styled.div`
  text-align: center;
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const CheckTermsWrapper = styled.div`
  width: auto;
`;

const CheckTermsContainer = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;

  border-radius: 8px;
  padding: 1.0416vw 1.5625vw;

  color: #fcfcfc;

  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #3a3d5a;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${RWD_LG(css`
    height: 60vh;
  `)};

  ${RWD_SM(css`
    width: 100%;
    height: 55vh;
    padding: 10px 15px;
  `)};
`;

const TermsUl = styled.ul`
  list-style-type: decimal;
  list-style-position: inside;
`;

const TermsLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0;
  line-height: 1.5;
`;

const TermsDetailUl = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`;
const TermsDetailLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0 8px 8px;
  line-height: 1.5;
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
  color: #fcfcfc;
  width: auto;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-left: 1.5625vw;
  ${RWD_MD(css`
    justify-content: space-between;
  `)};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #ccc;
  width: 20px;
  height: 20px;
  /* transform: scale(1.5); */
`;

const PhoneWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
`;

const SelectEl = styled.select`
  color: #fcfcfc;
  background: transparent;
  padding: 10px 0;
  width: 95%;
  font-size: clamp(13px, 0.833vw, 16px);

  &:focus {
    outline: none;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  min-width: 30%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

// #endregion

// #region private components

const CheckTermsTemplate = ({ nextStep, setTermsCheck, termsCheck }) => {
  const [isBottom, setIsBottom] = useState(false);
  const dom = useRef(null);
  useEffect(() => {
    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("scroll", handleScroll), true;
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      setTermsCheck(true);
    } else return;
  }, [isBottom]);

  const handleScroll = () => {
    const clientHeight = dom.current?.clientHeight;
    const scrollHeight = dom.current?.scrollHeight;
    const scrollTop = dom.current?.scrollTop;
    setIsBottom(clientHeight + scrollTop + 1 >= scrollHeight);

    // setPrivacyChecked(isBottom);
  };

  const terms = [
    {
      id: 1,
      title: [
        {
          Ch: "協議與接受",
          En: "Agreement and Acceptance",
        },
      ],
      content: [
        {
          Ch: [
            "在您註冊AVA的賬戶、存取平台或使用我們的服務時，即表示您同意進入一份具有法律約束力的協議。這些條款與條件將管轄您使用我們服務的行為。",
          ],
          En: [
            "When you register an account with AVA, access our platform, or use our services, you agree to enter into a legally binding agreement. These terms and conditions will govern your use of our services.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          Ch: "風險提示",
          En: "Risk Warning",
        },
      ],
      content: [
        {
          Ch: [
            "數位資產交易存在重大風險。數位資產價值可能波動劇烈，導致重大損失。您應根據您的財務狀況和投資目標，仔細考慮是否適合進行此類交易。",
            "您確認您已瞭解這些風險，並將對您的決策及因此產生的任何損失或責任承擔全部責任。",
          ],
          En: [
            "Digital asset trading involves significant risks. The value of digital assets can fluctuate greatly, potentially leading to substantial losses. You should carefully consider whether such trading is suitable for you based on your financial situation and investment objectives.",
            " You acknowledge that you understand these risks and will assume full responsibility for your decisions and any resulting losses or liabilities.",
          ],
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          Ch: "服務",
          En: "Services",
        },
      ],
      content: [
        {
          Ch: [
            "我們提供一個用於管理數位資產的平台，包括將您的資金分散投資於各個交易所以優化利潤。",
            "我們服務的具體細節，包括任何限制和要求，均詳細列出在產品條款中。",
          ],
          En: [
            "We provide a platform for managing digital assets, including diversifying your funds across various exchanges to optimize profits.",
            "The specific details of our services, including any limitations and requirements, are detailed in the product terms.",
          ],
        },
      ],
    },
    {
      id: 4,
      title: [
        {
          Ch: "費用",
          En: "Fees",
        },
      ],
      content: [
        {
          Ch: [
            "您同意支付使用我們服務所產生的所有相關費用，詳情請見我們的相關產品條款中的說明。",
          ],
          En: [
            "You agree to pay all related fees incurred in using our services, as detailed in our relevant product terms.",
          ],
        },
      ],
    },
    {
      id: 5,
      title: [
        {
          Ch: "賬戶管理",
          En: "Account Management",
        },
      ],
      content: [
        {
          Ch: [
            "您必須創建並維護您的賬戶才能存取我們的服務。我們保留拒絕創建賬戶或限制您擁有賬戶數量的權利。",
            "您需對您賬戶下的所有活動負責，並確保您的賬戶信息的安全性與機密性。",
          ],
          En: [
            "You must create and maintain your account to access our services. We reserve the right to refuse account creation or to limit the number of accounts you may hold.",
            "You are responsible for all activities under your account and must ensure the security and confidentiality of your account information.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: [
        {
          Ch: "交易",
          En: "Transactions",
        },
      ],
      content: [
        {
          Ch: [
            "您可以根據我們提供的服務進行交易。我們不保證任何交易在特定時間內完成。",
            "您應監控您的賬戶，以識別任何未授權的活動。我們對因未授權交易造成的損失不承擔責任。",
          ],
          En: [
            "You may conduct transactions as per the services we provide. We do not guarantee the completion of any transaction within a specific time frame.",
            "You should monitor your account to identify any unauthorized activities. We are not responsible for losses due to unauthorized transactions.",
          ],
        },
      ],
    },
    {
      id: 7,
      title: [
        {
          Ch: "安全",
          En: "Security",
        },
      ],
      content: [
        {
          Ch: [
            "您有責任保護您的賬戶和個人信息不被未經授權的訪問。",
            "您應隨時為您的賬戶和登錄憑證維護適當的安全措施。",
          ],
          En: [
            "You are responsible for protecting your account and personal information from unauthorized access.",
            "You should always maintain appropriate security measures for your account and login credentials.",
          ],
        },
      ],
    },
    {
      id: 8,
      title: [
        {
          Ch: "隱私",
          En: "Privacy",
        },
      ],
      content: [
        {
          Ch: ["我們承諾按照我們的隱私政策保護您的個人資料。"],
          En: [
            "We commit to protecting your personal data in accordance with our privacy policy.",
          ],
        },
      ],
    },
    {
      id: 9,
      title: [
        {
          Ch: "條款修改",
          En: "Modification of Terms",
        },
      ],
      content: [
        {
          Ch: [
            "我們保留隨時修改這些條款的權利。修改將通過電子郵件或在我們的網站上通知。繼續使用我們的服務即表示您接受新的條款。",
          ],
          En: [
            "We reserve the right to modify these terms at any time. Changes will be notified via email or on our website. Continued use of our services indicates your acceptance of the new terms.",
          ],
        },
      ],
    },
    {
      id: 10,
      title: [
        {
          Ch: "賬戶終止",
          En: "Account Termination",
        },
      ],
      content: [
        {
          Ch: [
            "您可以按照規定的程序關閉您的賬戶。在某些情況下，可能會限制賬戶的關閉。",
          ],
          En: [
            "You may close your account following the specified procedure. Account closure may be restricted under certain circumstances.",
          ],
        },
      ],
    },
    {
      id: 11,
      title: [
        {
          Ch: "限制與責任",
          En: "Limitations and Liability",
        },
      ],
      content: [
        {
          Ch: [
            "我們的責任僅限於您在過去12個月內支付給我們的費用。我們對間接損失，包括損失的利潤或機會，不承擔責任。",
          ],
          En: [
            "Our liability is limited to the fees you have paid us in the past 12 months. We are not liable for indirect losses, including lost profits or opportunities.",
          ],
        },
      ],
    },
    {
      id: 12,
      title: [
        {
          Ch: "價值單位",
          En: "Unit of Value",
        },
      ],
      content: [
        {
          Ch: [
            "除非在平台上另有明確標示，所有提及或計算的價值單位均以「AVAPoint」作為衡量基準。",
            "「AVAPoint」是我們專門為平台交易和服務所設計的計價單位，旨在為用戶提供一致和標準化的 價值衡量方式。",
          ],
          En: [
            "Unless otherwise explicitly indicated on the platform, all mentioned or calculated value units are measured in 'AVAPoint'.",
            "'AVAPoint' is a unit of valuation specifically designed for platform transactions and services, intended to provide users with a consistent and standardized method of value measurement.",
          ],
        },
      ],
    },
  ];

  return (
    <CheckTermsWrapper>
      <CheckTermsTitle>Terms and Conditions</CheckTermsTitle>
      <CheckTermsContainer ref={dom}>
        <TermsUl>
          {terms.map((data, id) => (
            <Fragment>
              <TermsLi>{data.title[0].En}</TermsLi>
              <TermsDetailUl>
                {data.content[0].En.map((item) => (
                  <TermsDetailLi>{item}</TermsDetailLi>
                ))}
              </TermsDetailUl>
            </Fragment>
          ))}
        </TermsUl>
      </CheckTermsContainer>

      <CheckboxWrapper>
        <CheckBox
          type="checkbox"
          checked={termsCheck}
          onChange={() => {}}
        ></CheckBox>
        <CheckboxContainer>
          <Lang
            datakey="addalgo_agree"
            defaultText="I have read and agree to the above terms and conditions"
          />
        </CheckboxContainer>
      </CheckboxWrapper>
      <ActionContainer>
        <ButtonWrapper>
          <Button
            type={"confirm"}
            disabled={!termsCheck}
            variant="contained"
            onClick={nextStep}
          >
            <ButtonText>
              {termsCheck ? "Next" : "Scroll down to agree"}
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </ActionContainer>
    </CheckTermsWrapper>
  );
};

const EmailTemplate = ({ nextStep, account, setAccount, setLoading }) => {
  const navigate = useNavigate();
  const [_sendEmail, { isSuccess, isError, error, isLoading }] =
    useSendEmailMutation();
  const [errorMsg, setErrorMsg] = useState("");
  const valid = account && EmailFormat(account);

  const [referenceCode, setReferenceCode] = useState("");

  // 推薦碼導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setReferenceCode(query.get("invites"));
  }, [search]);

  const handleSendMail = async (e) => {
    e.preventDefault();
    _sendEmail({
      body: {
        Account: account,
        ReferenceCode: referenceCode ?? "",
      },
    });
  };

  useEffect(() => {
    if (getAuthToken().token) return navigate(routes.algo_v2);
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [account]);

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      SuccessMySwal({
        title: handleParseLanguage("register_verification_notice"),
        text: handleParseLanguage("verify_code_sent"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          nextStep();
        }
      });
    }
    if (isError) setErrorMsg(error.data.Message);

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <Field htmlFor="email">
        <RegisterInput
          placeholder={`${handleParseLanguage("email")} (${handleParseLanguage(
            "account"
          )})`}
          onChange={(e) => setAccount(e.target.value)}
          id="email"
        />

        {/* <TextField
          label="Email (Account)"
          variant="standard"
          value={account}
          onChange={(e) => setAccount(e.target.value)}
        /> */}
      </Field>

      <ErrorMsg>{errorMsg}</ErrorMsg>

      <ActionContainer>
        <ButtonWrapper>
          <Button
            style={{
              cursor: "pointer",
              border: "1px solid #fcfcfc",
            }}
            type="button"
            onClick={() => navigate(routes.landing)}
          >
            <ButtonText>{handleParseLanguage("home")}</ButtonText>
          </Button>
          <Button type={"confirm"} disabled={!valid} onClick={handleSendMail}>
            <ButtonText>{handleParseLanguage("send_verify_code")}</ButtonText>
          </Button>
        </ButtonWrapper>

        {/* <Field>
          <Button
            disabled={!account || !verifications.email.test(account)}
            variant="contained"
            onClick={handleSendMail}
          >
            Send Verify Code
          </Button>
        </Field>
        <Field>
          <Button onClick={() => navigate(routes.landing)}>Home</Button>
        </Field> */}
      </ActionContainer>
    </>
  );
};

const VerifyEmailTemplate = ({ nextStep, account, code, setLoading }) => {
  const [_verifyEmail, { isSuccess, isError, error, isLoading }] =
    useVerifyEmailMutation();
  const [verifyCode, setVerifyCode] = useState(code ?? "");
  const valid = verifyCode;
  const [errorMsg, setErrorMsg] = useState("");

  const handleVerify = async (e) => {
    e.preventDefault();
    _verifyEmail({
      body: {
        Account: account,
        VerifyCode: code ?? verifyCode,
      },
    });
  };

  useEffect(() => {
    setErrorMsg("");
  }, [verifyCode]);

  useEffect(() => {
    if (isSuccess) {
      // 多國語言

      SuccessMySwal({
        title: handleParseLanguage("verification_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          nextStep();
        }
      });
    }

    if (isError) setErrorMsg(error.data.Message);
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <Field>
        <RegisterInput
          placeholder={handleParseLanguage("register_verification_directions")}
          onChange={(e) => setVerifyCode(e.target.value)}
          value={verifyCode}
          autocomplete="off"
        />
        {/* <TextField
          label="Get Verify Code From Email"
          variant="standard"
          value={verifyCode}
          onChange={(e) => setVerifyCode(e.target.value)}
        /> */}
      </Field>

      <ErrorMsg>{errorMsg}</ErrorMsg>

      <ActionContainer>
        <ButtonWrapper>
          <Button
            type={"confirm"}
            disabled={!valid}
            variant="contained"
            onClick={handleVerify}
          >
            <ButtonText>{handleParseLanguage("verify")}</ButtonText>
          </Button>
        </ButtonWrapper>
      </ActionContainer>
    </>
  );
};

const TelegramTemplate = ({ nextStep, account, setLoading }) => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState("");
  const [_verifyTelegram, { isSuccess, isError, error, isLoading }] =
    useVerifyTelegramMutation();
  const [verifyCode, setVerifyCode] = useState("");
  const valid = account && verifyCode;
  const [errorMsg, setErrorMsg] = useState("");

  const redirectUrl = `${process.env.REACT_APP_apiv2Host}/telegram/callback`;
  const loginUrl = `https://t.me/${
    process.env.REACT_APP_telegramBotName
  }?start=login&redirect_url=${encodeURIComponent(redirectUrl)}`;

  // 判斷註冊完登入後需不需要導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setRedirect(query.get("redirect"));
  }, [search]);

  const handleSendVerifyCode = (e) => {
    e.preventDefault();
    window.location.href = loginUrl;
  };

  const handleVerify = (e) => {
    e.preventDefault();
    _verifyTelegram({
      body: {
        Account: account,
        VerifyCode: verifyCode,
      },
    });
  };

  const handleSkip = (e) => {
    e.preventDefault();
    nextStep();
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言

      SuccessMySwal({
        title: handleParseLanguage("verification_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          if (redirect) {
            navigate({
              pathname: `${routes.login_v2}?redirect=${redirect}`,
            });
          } else {
            navigate(routes.login_v2);
          }
        }
      });
    }

    if (isError) setErrorMsg(error.data.Message);
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSuccess, isError, isLoading]);

  const handleLogin = () => {
    if (redirect) {
      navigate({
        pathname: `${routes.login_v2}?redirect=${redirect}`,
      });
    } else {
      navigate(routes.login_v2);
    }
  };

  return (
    <>
      <Qrcode>
        <QRCodeCanvas
          value={loginUrl}
          bgColor={"transparent"}
          fgColor={"#fcfcfc"}
          onClick={handleSendVerifyCode}
        />
      </Qrcode>

      <FieldWrapper>
        <TelegramField>
          <TelegramInput
            placeholder={handleParseLanguage("verification_code")}
            onChange={(e) => setVerifyCode(e.target.value)}
            value={verifyCode}
            autocomplete="off"
          />
        </TelegramField>
        <QrcodeBtn onClick={handleSendVerifyCode}>
          {handleParseLanguage("get_code")}
        </QrcodeBtn>
      </FieldWrapper>

      {/* <TextField
          label="Verify Code"
          variant="standard"
          value={verifyCode}
          onChange={(e) => setVerifyCode(e.target.value)}
        /> */}

      <ErrorMsg>{errorMsg}</ErrorMsg>

      <ActionContainer>
        <ButtonWrapper>
          <Button
            style={{
              cursor: "pointer",
              border: "1px solid #fcfcfc",
            }}
            type="button"
            onClick={handleLogin}
          >
            <ButtonText>{handleParseLanguage("login")}</ButtonText>
          </Button>
          <Button
            type={"confirm"}
            disabled={!valid}
            variant="contained"
            onClick={handleVerify}
          >
            <ButtonText>{handleParseLanguage("verify")}</ButtonText>
          </Button>
        </ButtonWrapper>
        {/* <Field>
          <Button
            variant="contained"
            onClick={handleVerify}
            disabled={!account || !verifyCode}
          >
            Verify
          </Button>
          <Button onClick={handleSkip}>Skip</Button>
        </Field> */}
      </ActionContainer>
    </>
  );
};

const PasswordTemplate = ({ account, setLoading, nextStep }) => {
  const navigate = useNavigate();
  const [_setPassword, { isSuccess, isError, error, isLoading }] =
    useSetPasswordMutation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [redirect, setRedirect] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");

  // 推薦碼導頁
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setReferenceCode(query.get("invites"));
    setRedirect(query.get("redirect"));
  }, [search]);

  const phoneCheck = (phone) => {
    if (countryCode === "886") {
      return TaiwanPhoneFormatCheck(phone);
    } else if (countryCode === "852") {
      return HongKongPhoneFormatCheck(phone);
    } else {
      return false;
    }
  };

  const valid =
    PasswordCheck(password) &&
    confirmPassword &&
    password === confirmPassword &&
    countryCode !== "" &&
    phone &&
    phoneCheck(phone);

  const [errorMsg, setErrorMsg] = useState("");

  const handleSetPassword = async (e) => {
    e.preventDefault();
    _setPassword({
      body: {
        Account: account,
        Password: password,
        ReferenceCode: referenceCode ?? "",
        Phone: `+${countryCode}${
          phone.startsWith("0") ? phone.slice(1) : phone
        }`,
      },
    });
  };

  useEffect(() => {
    setErrorMsg("");
  }, [password, confirmPassword]);

  useEffect(() => {
    if (isSuccess) {
      // 多國語言

      SuccessMySwal({
        title: handleParseLanguage("registration_successful"),
        text: handleParseLanguage("register_suggestion"),
        showCancelButton: true,
        cancelButtonText: handleParseLanguage("login"),
        confirmButtonText: handleParseLanguage("verify_now"),
      }).then((result) => {
        if (result.isConfirmed) {
          nextStep();
        } else {
          if (redirect) {
            navigate({
              pathname: `${routes.login_v2}?redirect=${redirect}`,
            });
          } else {
            navigate(routes.login_v2);
          }
        }
      });
    }

    if (isError) setErrorMsg(error.data.Message);

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <Field>
        {/* <TextField
          label="Password"
          variant="standard"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> */}
        <RegisterInput
          placeholder={handleParseLanguage("password")}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          autocomplete="off"
          type="password"
        />
      </Field>

      <Field>
        {/* <TextField
          label="Confirm Password"
          type="password"
          variant="standard"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        /> */}

        <RegisterInput
          placeholder={handleParseLanguage("confirm_password")}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          autocomplete="off"
          type="password"
        />
      </Field>
      <Notice>{handleParseLanguage("password_rule")}</Notice>
      <Field>
        {/* <TextField
          label="Confirm Password"
          type="password"
          variant="standard"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        /> */}
        <PhoneWrapper>
          <SelectWrapper>
            <SelectEl
              placeholder={handleParseLanguage("country_code")}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option disabled value="">
                {handleParseLanguage("country_code")}
              </option>
              <option value="886">+886</option>
              <option value="852">+852</option>
            </SelectEl>
          </SelectWrapper>

          <RegisterInput
            placeholder={handleParseLanguage("phone")}
            onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
            value={phone}
            autocomplete="off"
          />
        </PhoneWrapper>
      </Field>
      <InviteWrapper>
        <RegisterInput
          placeholder={`${handleParseLanguage(
            "referral_code"
          )}（${handleParseLanguage("optional")}）`}
          onChange={(e) => setReferenceCode(e.target.value)}
          value={referenceCode}
          autocomplete="off"
        />
        <Notice>{handleParseLanguage("register_referral_directions")}</Notice>
      </InviteWrapper>

      <ErrorMsg>{errorMsg}</ErrorMsg>

      <ActionContainer>
        <ButtonWrapper>
          <Button
            type={"confirm"}
            disabled={!valid}
            variant="contained"
            onClick={handleSetPassword}
          >
            <ButtonText>{handleParseLanguage("register")}</ButtonText>
          </Button>
        </ButtonWrapper>
        {/* <Field>
          <Button
            disabled={
              !password || !confirmPassword || password !== confirmPassword
            }
            variant="contained"
            onClick={handleSetPassword}
          >
            Register
          </Button>
        </Field> */}
      </ActionContainer>
    </>
  );
};
// #endregion

const Register = () => {
  const lang = useSelector(selectSystem).language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailVerifyCode } = useParams();

  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [step, setStep] = useState(1);

  // 推薦碼導頁
  const [isInvited, setIsInvited] = useState(false);
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get("invites")) {
      setIsInvited(true);
    }
  }, [search]);

  useEffect(() => {
    if (!emailVerifyCode) return;
    if (!account) handleGetVerifyAccount(emailVerifyCode);
    setStep(2);
  }, [emailVerifyCode]);

  const handleGetVerifyAccount = async (code) => {
    setLoading(true);
    const res = await API_GetVerifyAccount({ VerifyCode: code });
    setLoading(false);
    if (res?.ResultCode !== 0) {
      setStep(1);
      navigate(routes.register_v2);
      return UseSwal(res?.Message);
    }
    setAccount(res.Account);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleLastStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  return (
    <Wrapper>
      <RobotWrapper>
        <ChatBubble>
          <ChatContent>
            {(() => {
              switch (step) {
                case 1:
                  handleParseLanguage("register_robot_email");
                case 2:
                  return handleParseLanguage("register_robot_verification");
                case 3:
                  return parse(handleParseLanguage("register_robot_password"));
                case 4:
                  return parse(handleParseLanguage("register_robot_qrcode"));
              }
            })()}
          </ChatContent>
        </ChatBubble>
        <RobotImg>
          <img src={Robot} />
        </RobotImg>
      </RobotWrapper>
      <Header>
        <LogoWrapper>
          <LogoContainer onClick={handleBackToHome}>
            <img src={LogoImg} />
          </LogoContainer>
          <LanguageWrapper onClick={handleChangeLanguage}>
            <LanguageText>
              {lang?.toLowerCase() === "en" ? "EN" : "中"}
            </LanguageText>
          </LanguageWrapper>
        </LogoWrapper>
      </Header>
      {loading && <Loading />}

      <Container>
        <Title>{handleParseLanguage("register")}</Title>
        <Panel>
          {/* {step === 0 && (
            <CheckTermsTemplate
              termsCheck={termsCheck}
              setTermsCheck={setTermsCheck}
              nextStep={handleNextStep}
            />
          )} */}
          {step === 1 && (
            <EmailTemplate
              nextStep={handleNextStep}
              account={account}
              setAccount={setAccount}
              setLoading={setLoading}
            />
          )}
          {step === 2 && (
            <VerifyEmailTemplate
              nextStep={handleNextStep}
              account={account}
              code={emailVerifyCode}
              setLoading={setLoading}
              handleLastStep={handleLastStep}
            />
          )}
          {step === 3 && (
            <PasswordTemplate
              account={account}
              setLoading={setLoading}
              nextStep={handleNextStep}
            />
          )}
          {step === 4 && (
            <TelegramTemplate
              nextStep={handleNextStep}
              setLoading={setLoading}
              account={account}
            />
          )}
        </Panel>
        {/* {step !== 1 ||
          (step !== 4 && (
            <Back onClick={handleLastStep}>
              <AiOutlineArrowLeft />
            </Back>
          ))} */}
      </Container>
    </Wrapper>
  );
};

export default Register;
