import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAccountMining } from "../../slices/miningSlice";
import { selectSystem } from "../../slices/systemSlice";
import { selectAccount } from "../../slices/accountSlice";
import MiningTable from "./MiningTable";
import MiningEditModal from "./MiningEditModal";
import MiningReturnHistory from "./MiningReturnHistory";

import {
  useGetMiningQuery,
  useUpdateMiningInfoMutation,
  useReportMiningTxidMutation,
  useGetAhftReturnGroupMutation,
} from "../../services/miningApi";

import { useUpdateWatchingTutorialMutation } from "../../services/accountApi";

import {
  SuccessMySwal,
  ErrorMySwal,
  TutorialMySwal,
} from "../../global/MySwal";
import Icons from "../../global/icons";
import Lang from "../../global/Lang";
import Loading from "../../components/Loading/Loading";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { routes } from "../../../components/Router";

import { LangString } from "../../utils";
import { getLanguage } from "../../services/common";

import JoyrideComponent from "../../global/JoyrideComponent";

const Wrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding-bottom: 50px;
  color: #fcfcfc;

  ${RWD_MD(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const Card = styled.div`
  padding: 1.5vw 2vw 1.5vw 2vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  overflow: hidden;

  ${RWD_MD(css`
    padding: 20px;
  `)};
`;

const CardTitle = styled.div`
  padding-bottom: 20px;
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const CardAmountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  /* padding-top: 1.5vw; */
`;

const CardValue = styled.span`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);

  ${RWD_SM(css`
    font-size: min(max(1.25vw, 22px), 24px);
  `)};
`;

const CardCurrency = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  color: #54587b;
  padding-bottom: 2px;
`;

const CardProfit = styled.div`
  padding-left: 15px;
  color: #1cac2a;
`;

const CardSymbol = styled.span`
  font-size: min(max(1.145vw, 20px), 22px);

  padding-right: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const CardIcon = styled.div`
  position: absolute;
  bottom: -15%;
  right: 10px;
  opacity: 0.2;
  svg {
    width: 120px;
    height: 120px;
  }

  ${RWD_SM(css`
    svg {
      width: 100px;
      height: 100px;
    }
  `)};
  path {
    stroke: #54587b;
  }
`;

const MiningTableWrapper = styled.div``;

const CreateButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 3.125vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const CreateButton = styled.div`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  cursor: pointer;
  text-align: center;
`;

const CreateButtonText = styled.div`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const TutorialHelpIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fcfcfc;
  /* position: absolute; */
  bottom: 0px;
  cursor: pointer;
  width: 100%;
`;

const TutorialHelpIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: 0;

  & path {
    transition: all 0.3s linear;
  }

  svg {
    width: 25px;
    height: 25px;

    ${TutorialHelpIconWrapper}:hover & {
      & path {
        stroke: #0aecd1;
      }
    }
  }
`;

const TutorialHelpText = styled.div`
  font-size: 14px;
  padding-left: 5px;
  transition: all 0.3s linear;

  ${TutorialHelpIconWrapper} &:hover {
    color: #0aecd1;
  }
`;

const Mining = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tutorial = searchParams.get("tutorial");
  const questhubtutorial = searchParams.get("questhubtutorial");
  const handleJoyrideCallback = () => {
    navigate({
      pathname: "/v2/mining/addpackage/",
      search: "?tutorial=mining",
    });
  };
  const [userCloseTutorial, setUserCloseTutorial] = useState(false);
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createMining_pointsInfo",
      content: (
        <Lang
          datakey="mining_tutorial_1-1"
          defaultText="This area displays information related to the AVA Points earned through the Mining Package."
        />
      ),

      placement: "auto",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_miningTable",
      content: (
        <Lang
          datakey="mining_tutorial_1-2"
          defaultText='"This area displays the information of the Packages you have created. All detailed information can be operated and reviewed here.'
        />
      ),
      placement: "auto",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_createBtn",
      content: (
        <Lang
          datakey="mining_tutorial_1-3"
          defaultText='"Click the button to create a NEW Mining Package.'
        />
      ),
      placement: "auto",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  const handleTutorial = () => {
    const confirmText = LangString("yes", "Yes");
    const cancelText = LangString("nothanks", "No, Thanks");
    const titleText = LangString("welcome", "WELCOME !");
    const contentText = LangString(
      "mining_tutorial_title",
      "Would you like to start the Mining Trade beginner's tutorial?"
    );
    TutorialMySwal({
      title: titleText,
      text: contentText,
      showCancelButton: true,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        setJoyrideIsRun(true);
      } else {
        // 清除param
        searchParams.delete("questhubtutorial");
        searchParams.delete("tutorial");
        setSearchParams(searchParams);

        // 使用者不看教學
        setUserCloseTutorial(true);
        _updateWatchingTutorial({
          body: {
            TutorialKey: "Mining",
          },
        });
      }
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector(selectAccount).accountProfile;

  // 確認是否已經讀過新手教學
  useEffect(() => {
    if (
      (profileData.HasWatchedMiningTutorial === false && tutorial == null) ||
      questhubtutorial === "mining"
    ) {
      // 開始新手教學
      handleTutorial();
    }
  }, [profileData, searchParams]);

  // 新手教學
  const [
    _updateWatchingTutorial,
    {
      data: tutorialData,
      isSuccess: tutorialIsSuccess,
      isError: tutorialIsError,
      error: tutorialError,
    },
  ] = useUpdateWatchingTutorialMutation();

  useEffect(() => {
    if (tutorialIsSuccess) {
      // 使用者不看教學 直接return
      if (userCloseTutorial) return;
      const confirmText = LangString("confirm", "Confirm");
      const titleText = LangString("tutorial_complete", "Tutorial complete!");
      SuccessMySwal({
        title: titleText,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (tutorialIsError) {
      ErrorMySwal({
        title:
          tutorialError?.data?.ResultLanguages[getLanguage()] ||
          tutorialError?.data?.Message,
      });
    }
  }, [tutorialData, tutorialIsError, userCloseTutorial]);

  const [miningList, setMiningList] = useState([]);
  const [modalType, setModalType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [returnType, setReturnType] = useState("");
  const [txid, setTxid] = useState("");
  const [miningId, setMiningId] = useState("");
  const [reportTxidIsSuccess, setReportTxidIsSuccess] = useState(false);

  const [selectMiningData, setSelectMiningData] = useState([]);

  const [miningDeposit, setMiningDeposit] = useState([]);
  const [miningWithdrawal, setMiningWithdrawal] = useState([]);
  const [miningMined, setMiningMined] = useState([]);

  const [reportHistoryIsOpen, setReportHistoryIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);

  // websocket
  const bchusdt = useSelector(selectSystem).bchusdt;
  const bnbusdt = useSelector(selectSystem).bnbusdt;
  const btcusdt = useSelector(selectSystem).btcusdt;
  const cakeusdt = useSelector(selectSystem).cakeusdt;
  const ethusdt = useSelector(selectSystem).ethusdt;
  const iotausdt = useSelector(selectSystem).iotausdt;
  const neousdt = useSelector(selectSystem).neousdt;
  const eosusdt = useSelector(selectSystem).eosusdt;
  const etcusdt = useSelector(selectSystem).etcusdt;
  const solusdt = useSelector(selectSystem).solusdt;

  const {
    data: miningResponse,
    isSuccess: miningResponseIsSuccess,
    isError: miningResponseIsError,
    error: miningResponseError,
    refetch,
    isLoading,
    isFetching,
  } = useGetMiningQuery();

  useEffect(() => {
    // 每次進入頁面都刷新mining
    refetch();
    _reportHistory();
  }, []);

  useEffect(() => {
    if (miningResponseIsSuccess) {
      setMiningList(miningResponse?.Minings);
      setMiningDeposit(miningResponse?.MiningDeposits);
      setMiningWithdrawal(miningResponse?.MiningWithdrawals);
      setMiningMined(miningResponse?.Minings);
      dispatch(setAccountMining(miningResponse));
    }

    if (miningResponseIsError) {
      ErrorMySwal({
        title:
          miningResponseError?.data?.ResultLanguages[getLanguage()] ||
          miningResponseError?.data?.Message,
      });
    }
  }, [miningResponse, miningResponseIsError]);

  const [
    _updateMiningInfo,
    {
      data: miningInfoResponse,
      isSuccess: miningInfoResponseIsSuccess,
      isError: miningInfoResponseIsError,
      error: miningInfoResponseError,
      isLoading: miningInfoResponseIsLoading,
    },
  ] = useUpdateMiningInfoMutation();

  const [
    _reportMiningTxid,
    {
      data: miningTxidResponse,
      isSuccess: miningTxidResponseIsSuccess,
      isError: miningTxidResponseIsError,
      error: miningTxidResponseError,
      isLoading: miningTxidResponseIsLoading,
    },
  ] = useReportMiningTxidMutation();

  const [
    _reportHistory,
    {
      data: miningReportHistoryResponse,
      isSuccess: miningReportHistoryResponseIsSuccess,
      isError: miningReportHistoryResponseIsError,
      error: miningReportHistoryResponseError,
    },
  ] = useGetAhftReturnGroupMutation();

  useEffect(() => {
    if (miningReportHistoryResponseIsSuccess) {
      setReportData(miningReportHistoryResponse?.AhftGroups);
    }
  }, [miningReportHistoryResponse, miningReportHistoryResponseIsError]);

  useEffect(() => {
    if (miningInfoResponseIsSuccess) {
      // 多國語言
      const miningInfoTitle = LangString(
        "alert_update_success",
        "Update successful"
      );
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: miningInfoTitle,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
        }
      });
    }

    if (miningInfoResponseIsError) {
      ErrorMySwal({
        title:
          miningInfoResponseError?.data?.ResultLanguages[getLanguage()] ||
          miningInfoResponseError?.data?.Message,
      });
    }
  }, [miningInfoResponse, miningInfoResponseIsError]);

  useEffect(() => {
    if (miningTxidResponseIsSuccess) {
      // 多國語言
      const TxidTitle = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: TxidTitle,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsOpen(false);
          setReportTxidIsSuccess(true);
        }
      });
    }

    if (miningTxidResponseIsError) {
      ErrorMySwal({
        title:
          miningTxidResponseError?.data?.ResultLanguages[getLanguage()] ||
          miningTxidResponseError.data.Message,
      });
    }
  }, [miningTxidResponse, miningTxidResponseIsError]);

  const handleEdit = () => {
    switch (modalType) {
      case "name":
        _updateMiningInfo({
          body: {
            GroupId: miningId,
            Name: name,
          },
        });
        break;

      case "returntype":
        _updateMiningInfo({
          body: {
            GroupId: miningId,
            ReturnType: returnType,
          },
        });
        break;

      case "txid":
        _reportMiningTxid({
          body: {
            GroupId: miningId,
            Txid: txid,
          },
        });
        break;
    }
    // if (modalType === "name") {
    //   _updateMiningInfo({
    //     body: {
    //       GroupId: miningId,
    //       Name: name,
    //     },
    //   });
    // } else {
    //   _updateMiningInfo({
    //     body: {
    //       GroupId: miningId,
    //       ReturnType: returnType,
    //     },
    //   });
    // }
  };

  const transferUsdt = (target, value) => {
    // console.log(solusdt);
    switch (target) {
      case "BCH":
        return value * bchusdt;
      case "BNB":
        return value * bnbusdt;
      case "BTC":
        return value * btcusdt;
      case "CAKE":
        return value * cakeusdt;
      case "ETH":
        return value * ethusdt;
      case "IOTA":
        return value * iotausdt;
      case "NEO":
        return value * neousdt;
      case "EOS":
        return value * eosusdt;
      case "ETC":
        return value * etcusdt;
      case "SOL":
        return value * solusdt;
      case "USDT":
        return value * 1;
      default:
        if (target?.includes("USDT")) return value * 1;
        else return value * 1;
    }
  };

  const fakeMiningData = [
    {
      CreateTime: "2023/08/31",
      Deposit: 1000,
      GroupId: 928,
      Name: "Test",
      PackageId: 22,
      PackageName: "DeltaN_BTC_90Days",
      PeriodEndDate: null,
      PeriodItems: [],
      ReturnType: "no",
      Status: "waiting",
      SubDays: null,
      Target: "USDT",
      WithdrawalVolume: 0,
    },
  ];

  useEffect(() => {
    setSelectMiningData(miningList.filter((data) => data.GroupId === miningId));
  }, [miningId]);

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />
      {(isLoading ||
        isFetching ||
        miningInfoResponseIsLoading ||
        miningTxidResponseIsLoading) && <Loading />}
      <Container>
        <CardWrapper className="createMining_pointsInfo">
          <Card>
            <CardTitle>
              <Lang
                datakey="mining_card_deposit"
                defaultText="Total Deposit Volume"
              />
            </CardTitle>
            <CardCurrency>AVA Point</CardCurrency>
            <CardAmountWrapper>
              <CardSymbol>$ </CardSymbol>
              <CardValue>
                {miningDeposit.length > 0
                  ? miningDeposit
                      ?.map((data) => transferUsdt(data.Target, data.Amount))
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      )
                      .toFixed(2)
                  : 0}
              </CardValue>
            </CardAmountWrapper>
            <CardIcon>
              <Icons.DepositVolume />
            </CardIcon>
          </Card>
          <Card>
            <CardTitle>
              <Lang
                datakey="mining_card_mined"
                defaultText="Total Mined Volume"
              />
            </CardTitle>
            <CardCurrency>AVA Point</CardCurrency>
            <CardAmountWrapper>
              <CardSymbol>$ </CardSymbol>
              <CardValue>
                {miningMined.length > 0
                  ? miningMined
                      .map((data) => data.PeriodItems)
                      .flat()
                      .map((item) => transferUsdt(item.Target, item.Profit))
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      )
                      .toFixed(2)
                  : 0}
              </CardValue>
              {miningMined.length > 0 && (
                <CardProfit>
                  (
                  {(
                    (miningMined
                      .map((data) => data.PeriodItems)
                      .flat()
                      .map((item) => transferUsdt(item.Target, item.Profit))
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      ) /
                      miningMined
                        .map((data) => data.PeriodItems)
                        .flat()
                        .map((item) =>
                          transferUsdt(item.Target, item.FirstPeriodAmount)
                        )
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        )) *
                    100
                  ).toFixed(2)}
                  %)
                </CardProfit>
              )}
            </CardAmountWrapper>
            <CardIcon>
              <Icons.Trend />
            </CardIcon>
          </Card>
          <Card>
            <CardTitle>
              <Lang
                datakey="mining_card_withdrawn"
                defaultText="Withdrawn Volume"
              />
            </CardTitle>
            <CardCurrency>AVA Point</CardCurrency>
            <CardAmountWrapper>
              <CardSymbol>$ </CardSymbol>
              <CardValue>
                {miningWithdrawal.length > 0
                  ? miningWithdrawal
                      ?.map((data) => transferUsdt(data.Target, data.Amount))
                      .reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      )
                      .toFixed(2)
                  : 0}
              </CardValue>
            </CardAmountWrapper>
            <CardIcon>
              <Icons.Withdrawn />
            </CardIcon>
          </Card>
        </CardWrapper>
        <MiningTableWrapper className="createMining_miningTable">
          {(!isLoading || !isFetching) && tutorial == null && (
            <MiningTable
              miningData={miningList}
              setMiningId={setMiningId}
              setModalOpen={setIsOpen}
              setModalType={setModalType}
              transferUsdt={transferUsdt}
              setReportHistoryIsOpen={setReportHistoryIsOpen}
              reportTxidIsSuccess={reportTxidIsSuccess}
            />
          )}
          {tutorial === "mining" && (
            <MiningTable
              miningData={fakeMiningData}
              transferUsdt={transferUsdt}
            />
          )}
        </MiningTableWrapper>

        <CreateButtonWrapper>
          <CreateButton
            onClick={() => navigate(routes.addPackage_v2)}
            className="createMining_createBtn"
          >
            <CreateButtonText>
              <Lang datakey="add_algo" defaultText="Create" />
            </CreateButtonText>
          </CreateButton>

          <TutorialHelpIconWrapper onClick={() => handleTutorial()}>
            <TutorialHelpIcon>
              <Icons.Tutorial_Help />
            </TutorialHelpIcon>
            <TutorialHelpText>
              <Lang datakey="restart_tutorial" defaultText="Restart tutorial" />
            </TutorialHelpText>
          </TutorialHelpIconWrapper>
        </CreateButtonWrapper>
      </Container>
      {isOpen && (
        <MiningEditModal
          setIsOpen={setIsOpen}
          handleEdit={handleEdit}
          name={name}
          setName={setName}
          returnType={returnType}
          setReturnType={setReturnType}
          txid={txid}
          setTxid={setTxid}
          modalType={modalType}
          selectMiningData={selectMiningData}
          miningList={miningList}
        />
      )}
      {reportHistoryIsOpen && (
        <MiningReturnHistory
          setHistoryModalIsOpen={setReportHistoryIsOpen}
          data={reportData}
        />
      )}
    </Wrapper>
  );
};

export default Mining;
