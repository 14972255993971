import { useEffect, useState } from "react";
import KycInfo from "./KycInfo";
import TelegramInfo from "./TelegramInfo";
import EmailInfo from "./EmailInfo";
import WithdrawalInfo from "./WithdrawalInfo";
import RecommenderInfo from "./RecommenderInfo";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2/dist/sweetalert2";
import { FaArrowUp } from "react-icons/fa";
import { MdFilterListAlt } from "react-icons/md";
import styled from "styled-components";
import { TableContainer, Title } from "./Dashboard";
import { API_GetAccountsVerifies } from "../../../utils/api";

const TableHeadCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  justify-content: center;

  svg {
    color: #ccc;
    width: 0.8rem;
    height: 0.8rem;
  }

  &.left {
    justify-content: flex-start;
  }
`;

const Icon = styled.div`
  padding: 0.3rem;
  border-radius: 100%;
  transition: 200ms;
  &:hover {
    background: #ccc5;
  }
  svg.active {
    color: #1976d2;
  }
`;

const SortIcon = styled(Icon)`
  &.reverse {
    transform: rotate(180deg);
  }
`;

const FilterIcon = styled(Icon)`
  &.active {
    background: #ccc5;
  }
`;

const FilterWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 0.2rem 0.5rem;
  background: #fff;
  border: 1px solid #ccc;
  text-align: center;

  select {
    outline: none;
  }
  input {
    width: 100%;
  }
`;

const BTN_COLOR = {
  未申請: "info",
  待驗證: "warning",
  已驗證: "success",
  異常: "error",
};
const OPTIONS = ["請選擇", "未申請", "待驗證", "已驗證", "異常"];

const AccountTable = () => {
  const handleGetAccountsVerifies = async () => {
    const res = await API_GetAccountsVerifies();
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res.AccountVerifies);
  };

  const handleSort = (fieldName) => {
    if (fieldName !== sortColumn) {
      setSortDirection("ASC");
      setSortColumn(fieldName);
      return;
    }

    if (sortDirection === null) return setSortDirection("ASC");
    if (sortDirection === "ASC") return setSortDirection("DESC");

    setSortDirection("DESC");
    setSortColumn("CreateTime");
  };

  const handleOpenFilter = (e, fieldName) => {
    e.stopPropagation();
    if (fieldName === filterColumn) return setFilterColumn("");
    setFilterColumn(fieldName);
  };

  const handleFilter = (e, index) => {
    setColumns((prev) =>
      prev.with(index, { ...prev[index], filterStatus: e.target.value })
    );
  };

  const MySwal = withReactContent(Swal);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      fieldName: "AccountId",
      displayName: "帳號",
      filterStatus: "",
      filterType: "text",
    },
    {
      fieldName: "EmailVerifyStatus",
      displayName: "信箱驗證狀態",
      filterStatus: OPTIONS[0],
      filterType: "select",
    },
    {
      fieldName: "TelegramVerifyStatus",
      displayName: "Telegram 驗證狀態",
      filterStatus: OPTIONS[0],
      filterType: "select",
    },
    {
      fieldName: "KycVerifyStatus",
      displayName: "KYC 驗證狀態",
      filterStatus: OPTIONS[0],
      filterType: "select",
    },
    {
      fieldName: "WithdrawalVerifyStatus",
      displayName: "提領地址驗證狀態",
      filterStatus: OPTIONS[0],
      filterType: "select",
    },
    {
      fieldName: "RecommenderVerifyStatus",
      displayName: "推薦人驗證狀態",
      filterStatus: OPTIONS[0],
      filterType: "select",
    },
    {
      fieldName: "CreateTime",
      displayName: "註冊時間",
      filterStatus: null,
      filterType: "",
    },
  ]);
  const [sortColumn, setSortColumn] = useState("CreateTime");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [filterColumn, setFilterColumn] = useState("");
  const [filters, setFilters] = useState({});

  useEffect(() => {
    handleGetAccountsVerifies();
  }, []);

  useEffect(() => {
    const newFilter = {};
    columns
      .filter(
        (column) =>
          (column.filterType === "text" && column.filterStatus !== "") ||
          (column.filterType === "select" &&
            !!column.filterStatus &&
            column.filterStatus !== OPTIONS[0])
      )
      .map((column) => {
        newFilter[column.fieldName] = column.filterStatus;
      });
    setFilters(newFilter);
  }, [columns]);

  return (
    <>
      <Title>使用者帳號驗證狀態</Title>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              "&": {
                cursor: "default",
                zIndex: 2,
                position: "relative",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell key={index}>
                  <TableHeadCell
                    onClick={() => handleSort(column.fieldName)}
                    className={index === 0 && "left"}
                  >
                    {column.displayName}
                    <SortIcon
                      className={
                        column.fieldName === sortColumn &&
                        `${sortDirection === "DESC" && "reverse"}`
                      }
                    >
                      <FaArrowUp
                        className={
                          column.fieldName === sortColumn &&
                          `${!!sortDirection && "active"}`
                        }
                      />
                    </SortIcon>
                    {column.filterStatus !== null && (
                      <FilterIcon
                        className={
                          column.fieldName === filterColumn && "active"
                        }
                        onClick={(e) => handleOpenFilter(e, column.fieldName)}
                      >
                        <MdFilterListAlt
                          className={
                            ((column.filterType === "text" &&
                              column.filterStatus !== "") ||
                              (column.filterType === "select" &&
                                column.filterStatus !== OPTIONS[0])) &&
                            "active"
                          }
                        />
                      </FilterIcon>
                    )}
                  </TableHeadCell>
                  {filterColumn === column.fieldName &&
                    (column.fieldName === "AccountId" ? (
                      <FilterWrapper>
                        <input
                          placeholder="請輸入帳號"
                          value={column.filterStatus}
                          onChange={(e) => handleFilter(e, index)}
                        />
                      </FilterWrapper>
                    ) : (
                      <FilterWrapper>
                        <select
                          value={column.filterStatus}
                          onChange={(e) => handleFilter(e, index)}
                        >
                          {OPTIONS.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </FilterWrapper>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.sort((a, b) =>
                sortDirection === "ASC"
                  ? a[sortColumn] > b[sortColumn]
                    ? 1
                    : -1
                  : a[sortColumn] < b[sortColumn]
                  ? 1
                  : -1
              )
              ?.filter((info) =>
                Object.keys(filters).every((key) =>
                  info[key].includes(filters[key])
                )
              )
              ?.map((info, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&": { cursor: "pointer", whiteSpace: "nowrap" },
                    "&:hover": { background: "#eeea" },
                  }}
                >
                  {/* account */}
                  <TableCell>{info?.AccountId}</TableCell>
                  {/* email */}
                  <TableCell align="center">
                    <Button
                      color={BTN_COLOR[info?.EmailVerifyStatus]}
                      onClick={() =>
                        MySwal.fire({
                          html: <EmailInfo AccountId={info.AccountId} />,
                          showConfirmButton: false,
                        })
                      }
                    >
                      {info?.EmailVerifyStatus}
                    </Button>
                  </TableCell>
                  {/* telegram */}
                  <TableCell align="center">
                    <Button
                      color={BTN_COLOR[info?.TelegramVerifyStatus]}
                      onClick={() =>
                        MySwal.fire({
                          html: <TelegramInfo AccountId={info.AccountId} />,
                          showConfirmButton: false,
                        })
                      }
                    >
                      {info?.TelegramVerifyStatus}
                    </Button>
                  </TableCell>
                  {/* kyc */}
                  <TableCell align="center">
                    <Button
                      color={BTN_COLOR[info?.KycVerifyStatus]}
                      onClick={() =>
                        MySwal.fire({
                          html: (
                            <KycInfo
                              AccountId={info.AccountId}
                              handleGetAccountsVerifies={
                                handleGetAccountsVerifies
                              }
                            />
                          ),
                          showConfirmButton: false,
                        })
                      }
                    >
                      {info?.KycVerifyStatus}
                    </Button>
                  </TableCell>
                  {/* withdrawal */}
                  <TableCell align="center">
                    <Button
                      color={BTN_COLOR[info?.WithdrawalVerifyStatus]}
                      onClick={() =>
                        MySwal.fire({
                          html: <WithdrawalInfo AccountId={info.AccountId} />,
                          showConfirmButton: false,
                        })
                      }
                    >
                      {info?.WithdrawalVerifyStatus}
                    </Button>
                  </TableCell>
                  {/* recommender */}
                  <TableCell align="center">
                    <Button
                      color={BTN_COLOR[info?.RecommenderVerifyStatus]}
                      onClick={() =>
                        MySwal.fire({
                          html: <RecommenderInfo AccountId={info.AccountId} />,
                          showConfirmButton: false,
                        })
                      }
                    >
                      {info?.RecommenderVerifyStatus}
                    </Button>
                  </TableCell>
                  {/* create time */}
                  <TableCell align="center">{info?.CreateTime}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountTable;
