import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routes } from "../../../components/Router";

// --- styled
import styled from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { css } from "styled-components";

// --- plugins
import { TextField } from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2";

// --- api
import { verifications } from "../../../utils/verifications";
import { useLoginMutation } from "../../services/authApi";
import { setUser } from "../../slices/loginSlice";
import {
  getAuthToken,
  setLanguage,
  setNotification,
  setOnboarding,
  setTermsOfUser,
  setPhoneCheck,
} from "../../services/common";
import { clearAccount } from "../../slices/accountSlice";

import { API_GetToken } from "../../../utils/api";
import { EmailFormat, IsDevelopEnv } from "../../utils";

import LogoImg from "../../../img/ava_logo_dark.png";
import { changeLanguage, selectSystem } from "../../slices/systemSlice";
import { useSelector } from "react-redux";
import { handleParseLanguage } from "../../utils";
import { getLanguage } from "../../services/common";

// #region styled
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  background-color: #151944;
  color: #fcfcfc;
  font-family: Poppins;
`;

const LoginContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(600px, 40vw, 750px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 2vw 2.5vw;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  ${RWD_LG(css`
    width: clamp(550px, 60vw, 620px);
    padding: 4vw;
  `)};

  ${RWD_MD(css`
    width: 70vw;
  `)};

  ${RWD_SM(css`
    width: 90vw;
    padding: 15px;
  `)};
`;

const LoginTitle = styled.h3`
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
`;

const LoginForm = styled.form`
  /* padding: 0 2.5vw 2vw 2.5vw; */
`;

const LoginField = styled.label`
  width: 100%;
  padding: 8px 0;
`;

const LoingInput = styled.input`
  color: #fcfcfc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 10px;
  margin: 10px 0;
  width: 100%;
  font-size: clamp(13px, 0.833vw, 16px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  ${RWD_SS(css`
    min-width: 30vw;
  `)};
`;

const ButtonText = styled.span`
  padding: 8px 16px;
  text-align: center;
  font-size: min(max(1.0416vw, 14px), 20px);
  color: #fcfcfc;
  ${RWD_SM(css`
    min-width: 25vw;
    padding: 8px 10px;
  `)};
`;

const LoginActionContainer = styled.div`
  padding: 30px 0 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ErrorMsg = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  color: #f05d2f;
  height: 20px;
`;

const ForgotAndErrorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const ForgotPasswordButton = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  color: #fcfcfc;
  height: 20px;
  cursor: pointer;
`;

const Header = styled.header`
  height: 6.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${RWD_MD(css`
    height: 8vh;
  `)};
  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 81.88vw;
  ${RWD_MD(css`
    width: 90vw;
  `)};
`;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;
  cursor: pointer;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LoginCheckWrapper = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  color: #fcfcfc;
  text-align: right;
  * {
    cursor: pointer;
  }
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: end;
`;

const LanguageText = styled.div`
  cursor: pointer;
`;

// #endregion

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector(selectSystem).language;
  const [_login, { isSuccess, data, isError, error }] = useLoginMutation();

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [needLoginOldApi, setNeedLoginOldApi] = useState(true);

  const [errorMsg, setErrorMsg] = useState("");
  const [redirect, setRedirect] = useState("");

  // screen size function
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const valid = account && password && EmailFormat(account);

  const handleLogin = (e) => {
    e.preventDefault();
    _login({
      body: {
        Account: account,
        Password: password,
      },
    });
  };

  // 導頁至新聞
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setRedirect(query.get("redirect"));
  }, [search]);

  const handleOldVersionLogin = async () => {
    if (needLoginOldApi) {
      const res = await API_GetToken({ email: account, password });
      if (!!res.error_description) return Swal.fire(res.error_description);
      localStorage.setItem("token", res.access_token);
    } else {
      localStorage.setItem("token", "fake_token_for_skip_guard");
    }

    if (redirect) {
      navigate({
        pathname: `${routes.dataCenter_v2}?redirect=${redirect}`,
        replace: true,
      });
    } else {
      navigate(`${routes.dataCenter_v2}?tab=personaldata`, { replace: true });
    }
  };

  useEffect(() => {
    if (getAuthToken().token) return navigate(routes.algo_v2);
    if (getLanguage()) return;
    setLanguage("En");
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [account, password]);

  const handleRegister = (e) => {
    e.preventDefault();
    // 判斷註冊完登入後需不需要導頁
    if (redirect) {
      navigate({
        pathname: `${routes.register_v2}?redirect=${redirect}`,
        replace: true,
      });
    } else {
      navigate(routes.register_v2);
    }
  };

  const handleForgotPassword = () => {
    navigate(routes.forgotPassword_v2);
  };

  useEffect(() => {
    if (isSuccess) {
      setNotification("unread");
      setOnboarding("unchecked");
      setTermsOfUser("unconfirmed");
      setPhoneCheck("unconfirmed");
      dispatch(setUser(data));
      handleOldVersionLogin();

      // navigate(routes.algo_v2);
    }

    if (isError) {
      setErrorMsg(error.data?.ResultLanguages[getLanguage()]);
    }
  }, [data, isError]);

  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  const refreshViewHeight = () => {
    const vh = windowSize[1] * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    refreshViewHeight();
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, [windowSize][0]);

  return (
    <Wrapper>
      <Header>
        <LogoWrapper>
          <LogoContainer onClick={handleBackToHome}>
            <img src={LogoImg} />
          </LogoContainer>
          <LanguageWrapper onClick={handleChangeLanguage}>
            {lang && (
              <LanguageText>
                {lang?.toLowerCase() === "en" ? "EN" : "中"}
              </LanguageText>
            )}
          </LanguageWrapper>
        </LogoWrapper>
      </Header>

      <LoginContainer>
        <LoginTitle>{handleParseLanguage("login")}</LoginTitle>
        <LoginForm>
          <LoginField htmlFor="email">
            <LoingInput
              placeholder={`${handleParseLanguage(
                "email"
              )} (${handleParseLanguage("account")})`}
              onChange={(e) => setAccount(e.target.value)}
              id="email"
            />
            {/* <TextField
              label="Email (Account)"
              variant="standard"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            /> */}
          </LoginField>

          <LoginField htmlFor="password">
            <LoingInput
              placeholder={handleParseLanguage("password")}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              autocomplete="off"
              id="password"
            />
            {/* <TextField
              label="Password"
              type="password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          </LoginField>
          <ForgotAndErrorWrapper>
            <ErrorMsg>{errorMsg}</ErrorMsg>
            <ForgotPasswordButton onClick={handleForgotPassword}>
              {handleParseLanguage("forgot_password")}
            </ForgotPasswordButton>
          </ForgotAndErrorWrapper>

          <LoginActionContainer>
            <ButtonWrapper>
              <Button
                style={{
                  cursor: "pointer",
                  border: "1px solid #fcfcfc",
                }}
                type="button"
                onClick={handleRegister}
              >
                <ButtonText>
                  {handleParseLanguage("register")}
                  {/* <Lang datakey="cancel" defaultText="Cancel" /> */}
                </ButtonText>
              </Button>
              <Button type={"confirm"} disabled={!valid} onClick={handleLogin}>
                <ButtonText>{handleParseLanguage("login")}</ButtonText>
              </Button>
            </ButtonWrapper>
            {/* <LoginField>
              <Button
                variant="contained"
                onClick={handleLogin}
                disabled={
                  !account || !password || !verifications.email.test(account)
                }
              >
                login
              </Button>
            </LoginField> */}
          </LoginActionContainer>

          {IsDevelopEnv() && (
            <LoginCheckWrapper>
              <input
                id="loginOldApi"
                type="checkbox"
                value={needLoginOldApi}
                onChange={(e) => setNeedLoginOldApi(e.target.checked)}
                defaultChecked={true}
              />
              <label htmlFor="loginOldApi"> 同時登入舊版 API</label>
            </LoginCheckWrapper>
          )}
        </LoginForm>
      </LoginContainer>
    </Wrapper>
  );
};

export default Login;
