import { createSlice } from "@reduxjs/toolkit";
import {
  setAuthToken,
  removeNotification,
  removeOnboarding,
  removeAuthToken,
  removeLanguage,
  removeLanguageData,
  removeThemeMode,
  removePhoneCheck,
} from "../services/common";

const initialState = {
  token: null,
};

// Config slice
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUser: (state, action) => {
      setAuthToken(action.payload?.AccessToken, action.payload?.expires_in);
      state.token = action.payload?.access_token;
    },
    clearUser: (state, action) => {
      removeAuthToken();
      // removeLanguage();
      removeLanguageData();
      removeNotification();
      removeOnboarding();
      removeThemeMode();
      removePhoneCheck();
      state.token = null;
    },
  },
});

// Export actions
export const { setUser, clearUser } = loginSlice.actions;

// Export select
export const selectAuth = (state) => state.login;

// Export reducer
export default loginSlice.reducer;
