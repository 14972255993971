import { getLanguage, getLanguageData } from "../services/common";
import LanguageJson from "../global/LanguageData.json";

export const dateTimeFormat = (value) => {
  const utc = new Date(value);
  // 算出時差 (分鐘除以60算出小時)
  const offset = utc.getTimezoneOffset() / 60;
  const currentDateTime = new Date(utc.getTime() + -offset * 3600000);

  const formatDate =
    currentDateTime.getFullYear() +
    "-" +
    (currentDateTime.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    currentDateTime.getDate().toString().padStart(2, "0") +
    " " +
    currentDateTime.getHours().toString().padStart(2, "0") +
    ":" +
    currentDateTime.getMinutes().toString().padStart(2, "0");

  return formatDate;
};

export const TaiwanTimeFormat = () => {
  const d = new Date();
  const offset = d.getTimezoneOffset() / 60;
  const dt = d.setHours(d.getHours() + (offset + 8));

  const date = new Date(dt).toDateString();
  const time = `${new Date(dt).toTimeString().split(" ")[0]} ${
    new Date(dt).toTimeString().split(" ")[1]
  }`;

  return `${date} ${time}`;
};

export const CalendarFormat = (value) => {
  const year = value.getFullYear().toString();
  const month = (value.getMonth() + 1).toString().padStart(2, "0");
  const date = (value.getDate() + 1).toString().padStart(2, "0");

  return `${year}/${month}/${date}`;
};

export const TaiwanIdFormatCheck = (value) => {
  const regex = new RegExp(/^[A-Za-z][0-9]{9}$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const HongKongIdStartFormatCheck = (value) => {
  const regex = new RegExp(/^[A-Za-z]{1,2}[0-9]{6}$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const HongKongIdEndFormatCheck = (value) => {
  const regex = new RegExp(/^[0-9A-Z]{1}$/);
  console.log("check");
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const TaiwanPassportFormatCheck = (value) => {
  const regex = new RegExp(/^[0-9]{9}$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const HongKongPassportCheck = (value) => {
  const regex = new RegExp(
    /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
  );

  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const PasswordCheck = (value) => {
  // 8-20位英數字 可包含點符號
  // /^[A-Za-z0-9.,;:!@#%$*_+-=]{8,20}$/
  // 8-20位英文數字
  const regex = new RegExp(/^[a-zA-Z0-9]{8,20}$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const handleSortData = (type, status, data) => {
  switch (type) {
    case "PeriodEndDate":
    case "CreateTime": {
      if (!status) {
        const sorted = [...data].sort(
          (a, b) => new Date(a[type]) - new Date(b[type])
        );
        return sorted;
      } else {
        const sorted = [...data].sort(
          (a, b) => new Date(b[type]) - new Date(a[type])
        );
        return sorted;
      }
    }

    case "Name":
    case "NickName": {
      if (!status) {
        const sorted = [...data].sort((a, b) => a[type].localeCompare(b[type]));
        return sorted;
      } else {
        const sorted = [...data].sort((a, b) => b[type].localeCompare(a[type]));
        return sorted;
      }
    }
    case "ExpiredDay": {
      if (!status) {
        const sorted = [...data].sort((a, b) => a[type] - b[type]);
        return sorted;
      } else {
        const sorted = [...data].sort((a, b) => b[type] - a[type]);
        return sorted;
      }
    }
    case "TotalPayable":
    case "PaidPayable":
    case "RemainingPayable": {
      if (!status) {
        const sorted = [...data].sort(
          (a, b) =>
            removeCurrencyFormat(a[type]) - removeCurrencyFormat(b[type])
        );
        return sorted;
      } else {
        const sorted = [...data].sort(
          (a, b) =>
            removeCurrencyFormat(b[type]) - removeCurrencyFormat(a[type])
        );
        return sorted;
      }
    }
    default:
      return data;
  }
};

export const LangString = (datakey, defaultText = "") => {
  const lang = getLanguage();
  const langData = getLanguageData();

  if (!langData || langData?.length === 0) return defaultText;
  const data = langData.filter(({ Key }) => Key === datakey);
  if (data.length === 0) return defaultText;

  return data[0][lang] ?? "";
};

export const EmailFormat = (value) => {
  const regex = new RegExp(
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  );

  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const ChekcUserAgent = () => {
  if (/(iPhone|iPad|iOS)/i.test(navigator.userAgent)) {
    return "iOS";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return "Android";
  } else {
    return "PC";
  }
};

export const IsDevelopEnv = () => {
  return (
    window.location.href.includes("localhost") ||
    window.location.href.includes("develop")
  );
};

export const NewsDateFormat = (value) => {
  const date = new Date(value).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return date;
};

export const handleParseLanguage = (key) => {
  const lang = getLanguage();
  return LanguageJson?.[key]?.[0]?.[lang?.toLowerCase()];
};

export const TaiwanPhoneFormatCheck = (value) => {
  const regex = new RegExp(/^(09[0-9]{8}|9[0-9]{8})$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const HongKongPhoneFormatCheck = (value) => {
  const regex = new RegExp(/^[56789][0-9]{7}$/);
  if (value && !regex.test(value)) {
    return false;
  } else {
    return true;
  }
};
